import React, { useState } from "react";

const PrinterCompatibility = ({ category, brand, categoryData }) => {
  const [showTroubleshoot, setShowTroubleshoot] = useState(false);
  const [showInstallation, setShowInstallation] = useState(false);
  const [showCompatibleOS, setShowCompatibleOS] = useState(false);

  const currentCategory = categoryData.find(
    (item) => item.category === category && item.brand === brand
  );

  const { troubleshoot, installation, compatibility } = currentCategory || {};

  const renderPoints = (text) => {
    return text.split("\n").map((point, index) => {
      const trimmedPoint = point
        .trim()
        .replace(/^\*\*(\d+\.\s*.*?)\*\*:/, "<b>$1:</b>");
      return (
        <p key={index} dangerouslySetInnerHTML={{ __html: trimmedPoint }} />
      );
    });
  };

  const parseCompatibility = (compatibilityString) => {
    if (!compatibilityString) return {};

    const sections = compatibilityString.split(":");

    let compatibilityData = {};
    sections.forEach((section) => {
      const [key, value] = section.split(":").map((item) => item.trim());
      compatibilityData[key] = value;
    });

    return compatibilityData;
  };

  const parseAdditionalSupport = (compatibilityString) => {
    if (!compatibilityString) return [];

    const startIndex = compatibilityString.indexOf("Additionally");
    if (startIndex === -1) return [];

    const additionalSupportData = compatibilityString.substring(startIndex);

    const supports = additionalSupportData
      .split(/\n(?=\w)/)
      .map((item) => item.trim())
      .filter((item) => item.length > 0);

    return supports;
  };

  const compatibilityData = parseCompatibility(compatibility);
  const additionalSupportData = parseAdditionalSupport(compatibility);

  return (
    <div className="printer-compatibility-container p-5">
      <div className="buttons-container">
        <button
          className={showTroubleshoot ? "active" : ""}
          onClick={() => {
            setShowTroubleshoot(true);
            setShowInstallation(false);
            setShowCompatibleOS(false);
          }}
        >

          Troubleshoot


        </button>
        <button
          className={showInstallation ? "active" : ""}
          onClick={() => {
            setShowInstallation(true);
            setShowTroubleshoot(false);
            setShowCompatibleOS(false);
          }}
        >
          Installation
        </button>
        <button
          className={showCompatibleOS ? "active" : ""}
          onClick={() => {
            setShowCompatibleOS(true);
            setShowInstallation(false);
            setShowTroubleshoot(false);
          }}
        >
          Compatible Operating Systems
        </button>
      </div>

      <div className="info-container mt-4">
        {showTroubleshoot && (
          <div>
            <h2>Troubleshooting Steps</h2>
            {renderPoints(troubleshoot)}
          </div>
        )}

        {showInstallation && (
          <div>
            <h2>Installation Steps</h2>
            {renderPoints(installation)}
          </div>
        )}

        {showCompatibleOS && (
          <div>


            {Object.entries(compatibilityData).map(
              ([os, compatible], index) => (
                <>
                  <h3>{os}</h3>
                  {/* <p>{compatible}</p> */}
                </>
              )
            )}


          </div>
        )}
      </div>
    </div>
  );
};

export default PrinterCompatibility;
