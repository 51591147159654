import React, { useState } from "react";
import { FaTrash, FaEdit, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function SmallCard({
  title,
  content,
  onDelete,
  onUpdate,
  onAdd,
  isEditingMode,
  desc,
}) {
  const [newTitle, setNewTitle] = useState(title);
  const [newContent, setNewContent] = useState(content);

  const handleUpdate = () => {
    onUpdate(newTitle, newContent);
  };

  const handleCancel = () => {
    setNewTitle(title);
    setNewContent(content);
  };

  const handleEdit = () => {
    // Handle edit functionality
  };

  const handleAdd = () => {
    const newCategory = prompt("Enter a new category:");
    if (newCategory) {
      onAdd(newCategory);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        {isEditingMode ? (
          <>
            <input
              type="text"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              className="form-control mb-2"
            />
            <textarea
              value={newContent}
              onChange={(e) => setNewContent(e.target.value)}
              className="form-control mb-2"
            />
            <button className="btn btn-primary mr-2" onClick={handleUpdate}>
              Save
            </button>
            <button className="btn btn-secondary" onClick={handleCancel}>
              Cancel
            </button>
          </>
        ) : (
          <>
            <Link to="#">
              <h5 className="card-title">{title}</h5>
            </Link>
            <p className="card-text">{desc}</p>
            <p1 className="card-text">{content}</p1>

            {onDelete && (
              <button className="btn btn-primary mx-1" onClick={onDelete}>
                <FaTrash />
              </button>
            )}
            {onAdd && (
              <button className="btn btn-primary mx-1" onClick={handleAdd}>
                <FaPlus />
              </button>
            )}
            {onUpdate && (
              <button className="btn btn-primary mx-1" onClick={handleEdit}>
                <FaEdit />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
