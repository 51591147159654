import api from "../Service/apiHelper";

// admin dashboard
export const adminAllCount = async () => {
  try {
    const response = await api.post(`api/v1/admin/dash-count`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
// admin category
export const adminProductCategory = async () => {
  try {
    const response = await api.post(`api/v1/admin/category-count`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminHomeCategory = async () => {
  try {
    const response = await api.post(`api/v1/admin/read-owncategory`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminBrand = async (category) => {
  try {
    const response = await api.post(`api/v1/admin/brand/${category}`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminOwnBrand = async (category) => {
  try {
    const response = await api.post(`api/v1/admin/read-ownbrand/${category}`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminProductBrief = async (category, brand) => {
  try {
    const response = await api.post(
      `api/v1/admin/product-brief/${category}/${brand}`
    );
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminProductDelete = async (_id) => {
  try {
    const response = await api.post(`api/v1/admin/product-delete/${_id}`);
    return response.data;
  } catch (error) {
    if (error) {
      return error.response;
    }
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminGetSingleProduct = async (_id) => {
  try {
    const response = await api.post(`api/v1/admin/product-read/${_id}`);
    return response.data;
  } catch (error) {
    if (error) {
      return error.response;
    }
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminUpdateProduct = async (_id, formData) => {
  try {
    const response = await api.post(
      `api/v1/admin/product-update/${_id}`,
      formData
    );
    return response.data;
  } catch (error) {
    if (error) {
      return error.response.data;
    } else {
      throw new Error("An error occurred while fetching search results.");
    }
  }
};

export const adminDeleteProductField = async (_id, keysToDelete) => {
  try {
    const response = await api.post(
      `api/v1/admin/product-delete-product-keyvalue/${_id}`,
      { keysToDelete }
    );
    return response.data;
  } catch (error) {
    if (error) {
      return error.response.data;
    }
    throw new Error("An error occurred while fetching search results.");
  }
};
// admin category
export const adminReadAllNews = async () => {
  try {
    const response = await api.post(`api/v1/admin/read-all-news`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminAddNews = async (formdata) => {
  try {
    const response = await api.post(`api/v1/admin/add-news`, formdata);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminUpdateNews = async (_id, formdata) => {
  try {
    const response = await api.post(
      `api/v1/admin/update-news/${_id}`,
      formdata
    );
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminDeleteNews = async (_id) => {
  try {
    const response = await api.post(`api/v1/admin/delete-news/${_id}`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminReadSingleNews = async (_id) => {
  try {
    const response = await api.post(`api/v1/admin/read-news/${_id}`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
// admin user list
export const adminUserList = async () => {
  try {
    const response = await api.post(`api/v1/admin/user-list`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
export const adminUserDelete = async (_id) => {
  try {
    const response = await api.post(`api/v1/admin/user-delete/${_id}`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
export const addNewProduct = async (formData) => {
  try {
    const response = await api.post(`api/v1/admin/add-new-product`, formData);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
// for brand logo gv brand
export const addBrand = async (formData) => {
  try {
    const response = await api.post(`api/v1/admin/add-brand`, formData);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};
export const readBrand = async () => {
  try {
    const response = await api.post(`api/v1/admin/read-brand`);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching search results.");
  }
};

export const addcategoryBrandImgUrl = async (formdata) => {
  try {
    const response = await api.post(
      `api/v1/admin//add-owncategorybrand-data`,
      formdata
    );
    return response.data;
  } catch (error) {
    throw new Error(
      "An error occurred while fetching submit addcategoryBrandImgUrl"
    );
  }
};

export const getCategoryBrandById = async (id) => {
  try {
    const response = await api.post(`api/v1/admin/read-brand/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(
      "An error occurred while fetching submit addcategoryBrandImgUrl"
    );
  }
};
export const updateCategoryBrand = async (id, formdata) => {
  try {
    const response = await api.post(
      `api/v1/admin/update-brand/${id}`,
      formdata
    );
    return response.data;
  } catch (error) {
    throw new Error(
      "An error occurred while fetching submit addcategoryBrandImgUrl"
    );
  }
};
export const deleteCategoryBrand = async (id) => {
  try {
    const response = await api.post(`api/v1/admin/delete-brand/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(
      "An error occurred while fetching submit addcategoryBrandImgUrl"
    );
  }
};
export const addCategoryHomeCategory = async (formdata) => {
  try {
    const response = await api.post(
      `api/v1/admin/addcategory-homecategory`,
      formdata
    );
    return response.data;
  } catch (error) {
    throw new Error(
      "An error occurred while fetching submit addcategoryBrandImgUrl"
    );
  }
};

export const fetchCategoryHomeCategory = async (category) => {
  try {
    const response = await api.post(
      `api/v1/admin/getcategorybyid-homecategory?query=${category}`
    );
    return response.data;
  } catch (error) {
    throw new Error("An error  while fetching category");
  }
};

//
export const UpdateCategoryHomeCategory = async (oldCategory, newCategory) => {
  try {
    const response = await api.post(
      `api/v1/admin/updatecategory-homecategory`,
      { oldCategory, newCategory }
    );
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching category by id");
  }
};
export const deleteCategoryHomeCategory = async (oldCategory) => {
  try {
    const response = await api.post(
      `api/v1/admin/deletecategory-homecategory`,
      { oldCategory }
    );
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while fetching category by id");
  }
};

//
