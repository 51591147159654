import React from "react";
import { Link } from "react-router-dom";

export default function TroubleSection() {
  return (
    <div>
      <div className="border container p-2 m-3 w-100">
        <h1
          className="text-center p-2"
          style={{ fontSize: "2rem", fontWeight: "900" }}
        >
          Troubleshooting
        </h1>
        <div className="d-flex flex-wrap p-3">
          <div className="col-md-6 col-sm-12">
            <ul
              className="lisy-group list-group-item"
              style={{ listStyleType: "disc" }}
            >
              <Link to="/TSpage">
                <li className="p-2">
                  AI-Powered Future: A Partnership with Qualcomm on the Horizon
                </li>
              </Link>
            </ul>
            <ul
              className="lisy-group list-group-item"
              style={{ listStyleType: "disc" }}
            >
              <li className="p-2">
                AI-Powered Future: A Partnership with Qualcomm on the Horizon
              </li>
            </ul>
            <ul
              className="lisy-group list-group-item"
              style={{ listStyleType: "disc" }}
            >
              <li className="p-2">
                AI-Powered Future: A Partnership with Qualcomm on the Horizon
              </li>
            </ul>
            <ul
              className="lisy-group list-group-item"
              style={{ listStyleType: "disc" }}
            >
              <li className="p-2">
                AI-Powered Future: A Partnership with Qualcomm on the Horizon
              </li>
            </ul>
            <ul
              className="lisy-group list-group-item"
              style={{ listStyleType: "disc" }}
            >
              <li className="p-2">
                AI-Powered Future: A Partnership with Qualcomm on the Horizon
              </li>
            </ul>
            <ul
              className="lisy-group list-group-item"
              style={{ listStyleType: "disc" }}
            >
              <li className="p-2">
                AI-Powered Future: A Partnership with Qualcomm on the Horizon
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="text-center">
              <img
                src="https://5.imimg.com/data5/SELLER/Default/2021/3/PA/ZQ/LT/121439134/epson-colore-printer-inkjet.jpg"
                alt="https://gadgetfobia.com/"
                style={{ width: "35rem" }}
              ></img>
              <h2>Product Name</h2>
            </div>
          </div>
          <div className="text-center p-3 w-100">
            <button className="btn btn-primary">STILL NEED HELP ?</button>
          </div>
        </div>
      </div>
    </div>
  );
}
