const initialState = {
  compareItems: [],
};

const compareReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_COMPARE":
      return {
        ...state,
        compareItems: [...state.compareItems, action.payload],
      };
    case "REMOVE_FROM_COMPARE":
      return {
        ...state,
        compareItems: state.compareItems.filter(
          (item) => item._id !== action.payload._id
        ),
      };
    case "CLEAR_COMPARE_ITEMS":
      return {
        ...state,
        compareItems: [],
      };
    default:
      return state;
  }
};

export default compareReducer;
