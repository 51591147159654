import React from "react";
import { useLocation, useParams } from "react-router-dom";

export default function TSPage() {
  const location = useLocation();
  const { name, img, issue, question } = location.state;
  const { brand } = useParams(); // Fetching brand from params

  // Define troubleshooting steps for each issue and brand
  const troubleshootingSteps = {
    Brother: {
      "paper-jams": [
        "Brother brand: Here's how to fix paper jams step 1...",
        "Brother brand: Here's how to fix paper jams step 2...",
        // Add more steps as necessary
      ],
      "no-response": [
        "Brother brand: Steps to revive an unresponsive printer step 1...",
        "Brother brand: Steps to revive an unresponsive printer step 2...",
        // Add more steps as necessary
      ],
      // Add other issues as necessary
    },
    Canon: {
      "paper-jams": [
        "Canon brand: Here's how to fix paper jams step 1...",
        "Canon brand: Here's how to fix paper jams step 2...",
        // Add more steps as necessary
      ],
      "no-response": [
        "Canon brand: Steps to revive an unresponsive printer step 1...",
        "Canon brand: Steps to revive an unresponsive printer step 2...",
        // Add more steps as necessary
      ],
      // Add other issues as necessary
    },
    // Add other brands as necessary
  };

  // Function to render troubleshooting steps based on brand and issue
  const renderTroubleshootingSteps = () => {
    if (troubleshootingSteps[brand] && troubleshootingSteps[brand][issue]) {
      return (
        <ul className="p-3 ts-list">
          {troubleshootingSteps[brand][issue].map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </ul>
      );
    } else {
      return <p>No troubleshooting steps found for this issue.</p>;
    }
  };

  return (
    <div className="mx-auto p-4 ">
      <h1 className="mb-3 p-3 ts-product">
        <b>
          <i className=" bi bi-arrow-right "></i>
        </b>
        {name} Troubleshoot
      </h1>
      <div className="container">
        <div className="p-3 ts-Hed">
          <h3>
            <b>Question:</b> {question}
          </h3>
        </div>

        <div className="tspage">
          <ul className="p-3 ts-list">
            <li>
              If the wrinkles appear to run parallel to the short side of the
              paper, you are using Short Grain paper.
            </li>
            <img className="ts-image" src={img} alt="Printer" />
            {renderTroubleshootingSteps()}
          </ul>
        </div>
      </div>
    </div>
  );
}
