export const addToCompare = (item) => (dispatch, getState) => {
  const compareItems = getState().compare.compareItems;

  const isItemInCompare = compareItems.some(
    (compareItem) => compareItem._id === item._id
  );
  const hasSameCategory = compareItems.every(
    (compareItem) => compareItem.category === item.category
  );

  if (hasSameCategory) {
    if (compareItems.length < 4) {
      if (!isItemInCompare) {
        dispatch({
          type: "ADD_TO_COMPARE",
          payload: item,
        });
      } else {
        // Replace with user-facing notification
        alert("Item is already in the comparison list.");
      }
    } else {
      // Replace with user-facing notification
      alert("Only four items can be added for comparison.");
    }
  } else {
    // Implement a modal or confirmation dialog before dispatching
    if (
      window.confirm(
        "Changing categories will clear the comparison list. Continue?"
      )
    ) {
      dispatch(clearCompareItems());
      // Proceed to add the new item after clearing if desired
      dispatch({
        type: "ADD_TO_COMPARE",
        payload: item,
      });
      // Replace with user-facing notification
      alert("Comparison cleared and new item added due to different category.");
    }
  }
};

export const removeFromCompare = (item) => ({
  type: "REMOVE_FROM_COMPARE",
  payload: item,
});

export const clearCompareItems = () => ({
  type: "CLEAR_COMPARE_ITEMS",
});
