import api from "../Service/apiHelper";
// ###############################################################################

export const registerUser = async (userData) => {
  try {
    const response = await api.post(`api/v1/user/register`, userData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error registering user:", error);
      throw new Error("An error occurred while registering the user.");
    }
  }
};

//###############################################################################

export const loginUser = async (userData) => {
  try {
    const response = await api.post(`api/v1/user/login`, userData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      throw new Error("Error in Logging User. Please try again later.");
    }
  }
};

//###############################################################################

export const currentUser = async (token) => {
  try {
    const response = await api.get(`api/v1/user/current-user`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      throw new Error("An error occurred while fetching current user.");
    }
  }
};

// #################################################################################

export const logoutUser = async (token) => {
  try {
    // Configure request headers to include the bearer token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    };

    // Send a POST request to the backend logout endpoint with the configured headers
    const response = await api.post(`api/v1/user/logouttest`, null, config);
    return response.data; // Return the response data
  } catch (error) {
    // If an error occurs, throw an error with a custom message
    throw new Error("Logout failed: " + error.message);
  }
};

export const userChangePassword = async (token, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    };
    const response = await api.post(
      `api/v1/user/change-password`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      throw new Error("An error occurred while fetching current user.");
    }
  }
};
// #######################################################################################
