import React, { useEffect, useState } from "react";
import { ReadHomeNews } from "../../../Service/newsService";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NewsCard from "./NewsCard";
import { Link } from "react-router-dom";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
      },
    },
  ],
};

const News = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await ReadHomeNews();
      if (response.success === true) {
        setData(response.results);
      }
    } catch (error) {
      console.log("Error in fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="news-section border ">
      <div className="row">
        <div className="col-6">
          <h2 className="News-h ">News You May Like </h2>
        </div>

        <div className="col-6 ">
          <Link to={"/News"}>
            <h4 className="News-h ">
              View ALL &nbsp; <i className=" bi bi-arrow-right News-h"></i>
            </h4>
          </Link>
        </div>
      </div>
      <Slider {...settings}>
        {data?.map((article) => (
          <div key={article._id}>
            <Link
              to={`/News/${article.title}`}
              style={{ textDecoration: "none" }}
            >
              <NewsCard article={article} />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default News;
