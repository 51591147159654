import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBrandData } from "../Service/Api"; // Adjust the import path as necessary

const CategoryPage = () => {
  const { category } = useParams(); // Retrieve the category name from URL
  const [brands, setBrands] = useState([]);
  const [count, setcount] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
    const fetchBrands = async () => {
      try {
        // Assuming getBrandData fetches brands based on category name
        // You might need to adjust this call based on your actual API
        const data = await getBrandData(category);
        if (data.success) {
          setcount(data.totalCount);
          // Sort the brands array alphabetically by the brand property
          const sortedBrands = data.data.sort((a, b) => {
            return a.brand.localeCompare(b.brand);
          });
          setBrands(sortedBrands);
        } else {
          console.error("Failed to fetch brands");
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, [category]); // Re-fetch whenever the category name changes

  return (
    <div className="container p-5">
      <h2 className="p-3 Heading-text">
        <u>{category}</u>
        <hr />
        <u>Total Brands:{count}</u>
      </h2>
      <div className="cards">
        {brands?.map((brand, index) => (
          <div
            className="card"
            key={index}
            onClick={() => {
              navigate(`/${category}/${brand.brand}`);
            }}
            style={{ cursor: "pointer", borderRadius: "30px" }}
          >
            {/* Display brand details. Adjust as needed. */}

            <img className="p-5" src={brand.imgUrl} alt={brand.brand} />
            <div className="card-body text-center">
              <p className="text">{brand.brand}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryPage;
