import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  adminGetSingleProduct,
  adminDeleteProductField,
} from "../../Service/admin-service";
import "./ProductModified.css"; // Import the custom CSS file

export default function ProductModified() {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [fieldToDelete, setFieldToDelete] = useState(null); // State to store the field to be deleted temporarily
  const { _id } = useParams();

  // Fetch the product data on component mount
  useEffect(() => {
    async function fetchProduct() {
      try {
        const response = await adminGetSingleProduct(_id);
        const data = response.data;
        setFormData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
        setLoading(false);
      }
    }

    fetchProduct();
  }, [_id]);

  // Function to handle deletion of field
  const handleDeleteField = async (key) => {
    try {
      await adminDeleteProductField(_id, {
        [key]: formData[key],
      });
      const updatedFormData = { ...formData };
      delete updatedFormData[key]; // Remove the field from formData
      setFormData(updatedFormData); // Update state
    } catch (error) {
      console.error("Error deleting field:", error);
      // Handle error, show error message to the user, etc.
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Generate table rows for product details
  const tableRows = Object.entries(formData).map(([key, value]) => (
    <tr key={key}>
      <td className="px-4 py-2 font-bold">{key.toUpperCase()}</td>
      <td className="px-4 py-2">{value}</td>
      <td className="px-4 py-2">
        <button className="delete-btn" onClick={() => handleDeleteField(key)}>
          Delete
        </button>
      </td>
    </tr>
  ));

  return (
    <div className="container mx-auto py-8">
      <div className="custom-table-container">
        <table className="custom-table">
          <thead>
            <tr className="custom-table-header">
              <th>Attribute</th>
              <th>Value</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="custom-table-body">{tableRows}</tbody>
        </table>
      </div>
    </div>
  );
}
