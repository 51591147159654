import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchForComparison } from "../Service/searchService";
import { addToCompare } from "./store/compareActions";
import { toast } from "react-toastify";

const ComparisonSearch = ({ onClose }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  // Access the comparison items from the Redux store
  const compareItems = useSelector((state) => state.compare.compareItems);
  // Determine the category to filter by, based on the first item in the comparison list
  const selectedCategory =
    compareItems.length > 0 ? compareItems[0].category : null;

  // Inside your comparison search component's useEffect hook that fetches search results

  useEffect(() => {
    const fetchResults = async () => {
      if (searchQuery.trim() !== "") {
        try {
          // Explicitly pass 'null' or a specific parameter to indicate all categories should be fetched
          const categoryFilter = selectedCategory ? selectedCategory : null;
          const response = await searchForComparison(
            searchQuery,
            categoryFilter
          );
          setSearchResults(response.results);
        } catch (error) {
          console.error("Failed to fetch search results:", error);
        }
      } else {
        // If no search query, potentially clear results or handle differently
        setSearchResults([]);
      }
    };

    fetchResults();
  }, [searchQuery, selectedCategory]); // Ensure dependencies are correctly listed

  const handleResultClick = (result) => {
    dispatch(addToCompare(result));
    toast.success("Item added to compare!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    onClose();
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedItemIndex((prevIndex) =>
        prevIndex < searchResults.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedItemIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (event.key === "Enter" && selectedItemIndex !== -1) {
      handleResultClick(searchResults[selectedItemIndex]);
    }
  };

  return (
    <div className="search-overlay border">
      <div className="search-container ">
        <span
          className="close"
          onClick={onClose}
          style={{ cursor: "pointer", color: "#fff" }}
        >
          &times;
        </span>
        <div
          style={{
            padding: "10px",
            backgroundColor: "#0a6075",
            color: "#fff",
          }}
        >
          Search and Add Product
        </div>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          className="search-input"
        />
        {searchQuery !== "" && (
          <div
            className="mt-1"
            style={{ overflowY: "auto", maxHeight: "375px" }}
            ref={containerRef}
          >
            {searchResults.map((result, index) => (
              <div
                className={`border p-3 ${
                  index === selectedItemIndex ? "bg-light" : ""
                }`}
                key={result._id}
                onClick={() => handleResultClick(result)}
                style={{
                  cursor: "pointer",
                  padding: "8px",
                }}
              >
                {result.model_name} - {result.category || "All Categories"} -{" "}
                {result.brand}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ComparisonSearch;
