import React, { createContext, useState } from "react";
import { topBrand } from "../Service/featureService";
export const TopBrandContext = createContext();

export const TopBrandProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(null);

  const fetchData = async (query) => {
    try {
      const response = await topBrand(query);
      setProducts(response.data);
      setCount(response.count);
      setError(null);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <TopBrandContext.Provider value={{ products, count, error, fetchData }}>
      {children}
    </TopBrandContext.Provider>
  );
};
