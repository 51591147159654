import api from "../Service/apiHelper";
// ###############################################################################
export const addToWishlist = async (accessToken, product_Id) => {
  try {
    if (!accessToken || typeof accessToken !== "string") {
      throw new Error("Invalid access token provided.");
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.post(
      `api/v1/feature/addwishlist`,
      { product_Id },
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(
      "An error occurred while adding the item to the favourite."
    );
  }
};
// ##############################################################
export const getWishlist = async (accessToken) => {
  try {
    if (!accessToken || typeof accessToken !== "string") {
      throw new Error("Invalid access token provided.");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await api.get(`api/v1/feature/getwishlist`, config);

    return response.data;
  } catch (error) {
    console.error("Error fetch item to favourite:", error);
    throw new Error(
      "An error occurred while adding the item to the favourite."
    );
  }
};
// ##############################################################
export const removeFromWishlist = async (_id, token) => {
  try {
    if (!token || typeof token !== "string") {
      throw new Error("Invalid access token provided.");
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await api.delete(
      `api/v1/feature/deletewishlist/${_id}`,
      config
    );

    return response.data;
  } catch (error) {
    console.error("Error removing item from favourite:", error);
    throw new Error(
      "An error occurred while removing the item from the favourite."
    );
  }
};

// ##############################################################

// #######################################################################################
