import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import SmallCard from "./SmallCard";
import { adminAllCount } from "../../Service/adminService";
import AdminTitle from "./AdminTitle";
export default function Dashboard() {
  const [countProduct, setcountProduct] = useState("");
  const [countCategory, setcountCategory] = useState("");
  const [countBrand, setcountBrand] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await adminAllCount();
        setcountProduct(response.ProductCount);
        setcountCategory(response.CategoryCount);
        setcountBrand(response.BrandCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <AdminTitle />

      <div className="row">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-4">
              <SmallCard title="Total Product" content={countProduct} />
            </div>
            <div className="col-md-4">
              <SmallCard title="Total Category" content={countCategory} />
            </div>
            <div className="col-md-4">
              <SmallCard title="Total Brand" content={countBrand} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
