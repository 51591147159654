import React from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const TrendingDetails = () => {
  const { category } = useParams();
  const formattedCategory = category.toUpperCase();

  // Static data for top items
  const topItems = {
    MOBILES: [
      {
        id: "6576e3f1f2378c94a9c996ef",
        name: "Apple iPhone 15 Pro Max",
        image:
          "https://mainprojectimg.s3.amazonaws.com/1-Apple iPhone 15 Pro Max.jpg",
        rating: 9,
        specs: {
          Chipset: "Apple A17 Pro (3 nm)",
          Cpu: "Hexa-core (2x3.78 GHz + 4x2.11 GHz)",
          Gpu: "Apple GPU (6-core graphics)",
          CardSlot: "No",
          Internal: "256GB 8GB RAM, 512GB 8GB RAM, 1TB 8GB RAM",
          Features: "HDR, Cinematic mode (4K@24/30fps)",
        },
        link: "/mobiles/Apple/6576e3f1f2378c94a9c996ef",
      },
      {
        id: "6602c98bd5bb9f277c7617e1",
        name: "Samsung Galaxy S24 Ultra",
        image:
          "https://mainprojectimg.s3.amazonaws.com/Samsung+Galaxy+S24+Ultra+12GB+RAM+%2B+512GB.jpg",
        rating: 9.5,
        specs: {
          DualSim: "Yes",
          Size: "5000 mAh, Li-Po Battery",
          Bluetooth: "Yes, v5.3",
          FingerprintSensor: "Yes, In Display",
          IpRating: "IP68",
          DustResistant: "Yes",
        },
        link: "/mobiles/Samsung/6602c98bd5bb9f277c7617e1",
      },
      {
        id: "6576e3f1f2378c94a9c998eb",
        name: "Google Pixel 8 Pro",
        image:
          "https://mainprojectimg.s3.amazonaws.com/1-Google Pixel 8 Pro.jpg",
        rating: 8,
        specs: {
          Chipset: "Google Tensor G3 (4 nm)",
          Cpu: "Nona-core (1x3.0 GHz Cortex-X3 & 4x2.45 GHz Cortex-A715 & 4x2.15 GHz Cortex-A510)",
          Gpu: "Immortalis-G715s MC10",
          CardSlot: "No",
          Internal:
            "128GB 12GB RAM, 256GB 12GB RAM, 512GB 12GB RAM, 1TB 12GB RAM",
          Features: "Auto-HDR, panorama",
        },
        link: "/mobiles/Google/6576e3f1f2378c94a9c998eb",
      },
      {
        id: "6576e3eef2378c94a9c98b76",
        name: "Oppo Find N3 Flip",
        image:
          "https://mainprojectimg.s3.amazonaws.com/13-Oppo Find N3 Flip.jpg",
        rating: 9,
        specs: {
          Chipset: "Mediatek MT6985 Dimensity 9200 (4 nm)",
          Cpu: "Octa-core (1x3.05 GHz Cortex-X3 & 3x2.85 GHz Cortex-A715 & 4x1.80 GHz Cortex-A510)",
          Gpu: "Immortalis-G715 MC11",
          CardSlot: "No",
          Internal: "256GB 12GB RAM, 512GB 12GB RAM",
          Features: "HDR, panorama",
        },
        link: "mobiles/Oppo/6576e3eef2378c94a9c98b76",
      },
      {
        id: "6576e3edf2378c94a9c9855f",
        name: "Samsung Galaxy Z Fold 5",
        image:
          "https://mainprojectimg.s3.amazonaws.com/9-Samsung Galaxy Z Fold5.jpg",
        rating: 9.2,
        specs: {
          Chipset: "Qualcomm SM8550-AC Snapdragon 8 Gen 2 (4 nm)",
          Cpu: "Octa-core (1x3.36 GHz Cortex-X3 & 2x2.8 GHz Cortex-A715 & 2x2.8 GHz Cortex-A710 & 3x2.0 GHz Cortex-A510)",
          Gpu: "Adreno 740",
          CardSlot: "No",
          Internal: "256GB 12GB RAM, 512GB 12GB RAM, 1TB 12GB RAM",
          Features: "HDR",
        },
        link: "/mobiles/Samsung/6576e3edf2378c94a9c9855f",
      },
      {
        id: "6576e3edf2378c94a9c983c6",
        name: "vivo X100 Pro",
        image: "https://mainprojectimg.s3.amazonaws.com/1-vivo X100 Pro.jpg",
        rating: 9.3,
        specs: {
          Chipset: "Mediatek MT6989 Dimensity 9300 (4 nm)",
          Cpu: "Octa-core (1x3.25 GHz Cortex-X4 & 3x2.85 GHz Cortex-X4 & 4x2.0 GHz Cortex-A720)",
          Gpu: "Immortalis-G720 MC12",
          CardSlot: "No",
          Internal:
            "256GB 12GB RAM, 256GB 16GB RAM, 512GB 16GB RAM, 1TB 16GB RAM",
          Features: "HDR",
        },
        link: "/mobiles/Vivo/6576e3edf2378c94a9c983c6",
      },
      {
        id: "6602c98bd5bb9f277c7617cf",
        name: "OnePlus 12",
        image:
          "https://mainprojectimg.s3.amazonaws.com/OnePlus+12+16GB+RAM+%2B+512GB.jpg",
        rating: 9.3,
        specs: {
          Chipset: "Qualcomm Snapdragon 8 Gen 3",
          Cpu: "	3.3 GHz, Octa Core Processor",
          Gpu: "Adreno 750",
          CardSlot: "No",
          Internal:
            "256GB 12GB RAM, 256GB 16GB RAM, 512GB 16GB RAM, 1TB 16GB RAM",
          Features: "HDR",
        },
        link: "/mobiles/OnePlus/6602c98bd5bb9f277c7617cf",
      },
      {
        id: "6576e3eff2378c94a9c98c8b",
        name: "OnePlus Open",
        image: "https://mainprojectimg.s3.amazonaws.com/1-OnePlus Open.jpg",
        rating: 9.6,
        specs: {
          Chipset: "Qualcomm SM8550-AB Snapdragon 8 Gen 2 (4 nm)",
          Cpu: "Octa-core (1x3.2 GHz Cortex-X3 & 2x2.8 GHz Cortex-A715 & 2x2.8 GHz Cortex-A710 & 3x2.0 GHz Cortex-A510)",
          Gpu: "Adreno 740",
          CardSlot: "No",
          Internal: "512GB 16GB RAM",
          Features: "HDR",
        },
        link: "/mobiles/OnePlus/6576e3eff2378c94a9c98c8b",
      },
      {
        id: "6576e3eef2378c94a9c98ac1",
        name: "Realme GT3",
        image: "https://mainprojectimg.s3.amazonaws.com/10-Realme GT3.jpg",
        rating: 8.6,
        specs: {
          Chipset: "Qualcomm SM8475 Snapdragon 8+ Gen 1 (4 nm)",
          Cpu: "Octa-core (1x3.0 GHz Cortex-X2 & 3x2.5 GHz Cortex-A710 & 4x1.80 GHz Cortex-A510)",
          Gpu: "Adreno 730",
          CardSlot: "No",
          Internal:
            "128GB 8GB RAM, 256GB 12GB RAM, 256GB 16GB RAM, 512GB 16GB RAM, 1TB 16GB RAM",
          Features: "HDR, panorama",
        },
        link: "/mobiles/Realme/6576e3eef2378c94a9c98ac1",
      },
      {
        id: "6576e3f1f2378c94a9c99765",
        name: "Asus Zenfone 10",
        image: "https://mainprojectimg.s3.amazonaws.com/1-Asus Zenfone 10.jpg",
        rating: 8.9,
        specs: {
          Chipset: "Qualcomm SM8550-AB Snapdragon 8 Gen 2 (4 nm)",
          Cpu: "Octa-core (1x3.2 GHz Cortex-X3 & 2x2.8 GHz Cortex-A715 & 2x2.8 GHz Cortex-A710 & 3x2.0 GHz Cortex-A510)",
          Gpu: "Adreno 740",
          CardSlot: "No",
          Internal:
            "128GB 8GB RAM, 256GB 8GB RAM, 256GB 16GB RAM, 512GB 16GB RAM",
          Features: "LED flash, HDR, panorama",
        },
        link: "/mobiles/ASUS/6576e3f1f2378c94a9c99765",
      },
      // Add more mobile items here...
    ],
    LAPTOPS: [
      {
        id: "6576dcad281ec7002e2ef102",
        name: "Asus Zenbook 14X OLED 2023 M5401QA-KM541WS Laptop",
        image:
          "https://mainprojectimg.s3.amazonaws.com/43-Asus Zenbook 14X OLED 2023 M5401QA_KM541WS Laptop.jpg",
        rating: 8,
        specs: {
          Core: "Hexa Core, 12 Threads",
          RAM: "16 GB LPDDR4X",
          Storage: "512 GB",
          Speed: "6 x 3.3 GHz (Turbo Speed upto 4.2 GHz) Cores",
          Resolution: "2880 x 1800 pixels",
          GPU: "AMD Radeon AMD",
          OS: "Windows 11 Home (64-bit)",
        },
        link: "/laptops/ASUS/6576dcad281ec7002e2ef1f9",
      },
      {
        id: "6576dcad281ec7002e2ef103",
        name: "Acer Chromebook 714",
        image:
          "https://mainprojectimg.s3.amazonaws.com/307-Acer Chromebook 714 CB714 Laptop.jpg",
        rating: 7.5,
        specs: {
          Core: "Dual Core, 4 Threads",
          RAM: "16 GB DDR4",
          Storage: "64 GB",
          Speed: "2 x Cores",
          Resolution: "1920 x 1080 pixels",
          GPU: "Intel HD 620",
          OS: "Chrome OS",
        },
        link: "/laptops/Acer/6576dcac281ec7002e2eee51",
      },
      {
        id: "6576dcaf281ec7002e2efa59",
        name: "HP Spectre x360",
        image:
          "https://mainprojectimg.s3.amazonaws.com/170-HP Spectre x360 2023 13_5_ef2034TU Laptop.jpg",
        rating: 8,
        specs: {
          Core: "10 Cores (2P + 8E), 12 Threads",
          RAM: "32 GB LPDDR4X",
          Storage: "1 TB",
          Speed:
            "2 x (Turbo Speed upto 5 GHz) Performance Cores 8 x (Turbo Speed upto 3.7 GHz) Efficient Cores",
          Resolution: "3000 x 2000 pixels",
          GPU: "Intel Iris Xe Graphics",
          OS: "Windows 11 Home (64-bit)",
        },
        link: "/laptops/HP/6576dcaf281ec7002e2efa59",
      },
      {
        id: "6576dcad281ec7002e2ef34d",
        name: "Asus ROG Strix",
        image:
          "https://mainprojectimg.s3.amazonaws.com/784-Asus ROG Strix G731GT_AU006T Laptop.jpg",
        rating: 9,
        specs: {
          Core: "24 Cores (8P + 16E), 32 Threads",
          RAM: "16 GB DDR5",
          Storage: "1 TB",
          Speed: "6 x 3.3 GHz (Turbo Speed upto 4.2 GHz) Cores",
          Resolution: "2560 x 1600 pixels",
          GPU: "NVIDIA GeForce RTX 4070",
          OS: "Windows 11 Home (64-bit)",
        },
        link: "/laptops/ASUS/6576dcad281ec7002e2ef34d",
      },
      {
        id: "6576dcad281ec7002e2ef59b",
        name: "ROG Zephyrus G14",
        image:
          "https://mainprojectimg.s3.amazonaws.com/973-ROG Zephyrus G14.jpg",
        rating: 7.5,
        specs: {
          Core: "8",
          RAM: "16 GB",
          Storage: "1 TB",
          Speed: "3.0 GHz with Turbo boost up to 4.6 GHz",
          Resolution: "2880 x 1800 pixels",
          GPU: "NVIDIA GeForce RTX 3050",
          OS: "Windows 10 Home",
        },
        link: "/laptops/ASUS/6576dcad281ec7002e2ef59b",
      },
      {
        id: "6576dcb0281ec7002e2eff01",
        name: "Lenovo Yoga 9i",
        image:
          "https://mainprojectimg.s3.amazonaws.com/362-Lenovo Yoga 9i 82BG005JIN Laptop.jpg",
        rating: 9,
        specs: {
          Core: "Quad Core, 8 Threads",
          RAM: "16 GB LPDDR4X",
          Storage: "1 TB",
          Speed: "4 x (Turbo Speed upto 4.8 GHz) Cores",
          Resolution: "3840 x 2160 pixels",
          GPU: "Intel Iris Xe Graphics",
          OS: "Windows 10 (64-bit)",
        },
        link: "/laptops/Lenovo/6576dcb0281ec7002e2eff01",
      },
      {
        id: "6576dcad281ec7002e2ef4e9",
        name: "Asus VivoBook 15",
        image:
          "https://mainprojectimg.s3.amazonaws.com/795-Asus VivoBook 15 X512FB Laptop.jpg",
        rating: 7,
        specs: {
          Core: "	Quad Core, 4 Threads",
          RAM: "4 GB DDR4",
          Storage: "1 TB",
          Speed: "4 x 2.1 GHz (Turbo Speed upto 3.9 GHz) Cores",
          Resolution: "1920 x 1080 pixels",
          GPU: "Intel UHD 620",
          OS: "	Windows 10 Home Basic (64-bit)",
        },
        link: "/laptops/ASUS/6576dcad281ec7002e2ef4e9",
      },
      {
        id: "6576dcaf281ec7002e2efb4a",
        name: "HP Pavilion Plus",
        image:
          "https://mainprojectimg.s3.amazonaws.com/411-HP Pavilion Plus _14_eh1047TU Laptop.jpg",
        rating: 8,
        specs: {
          Core: "14 Cores (6P + 8E), 20 Threads",
          RAM: "16 GB DDR4",
          Storage: "512 GB",
          Speed:
            "6 x (Turbo Speed upto 5 GHz) Performance Cores 8 x (Turbo Speed upto 3.7 GHz) Efficient Cores",
          Resolution: "2880 x 1800 pixels",
          GPU: "Intel Integrated Iris Xe",
          OS: "Windows 11 Home (64-bit)",
        },
        link: "/laptops/HP/6576dcaf281ec7002e2efb4a",
      },
      {
        id: "6576dcb0281ec7002e2f01a0",
        name: "Microsoft Surface Pro Laptop",
        image:
          "https://mainprojectimg.s3.amazonaws.com/64-Microsoft Surface Pro Laptop.jpg",
        rating: 7,
        specs: {
          Core: "Dual Core, 4 Threads",
          RAM: "8 GB DDR3",
          Storage: "256 GB",
          Speed: "2 x 2.6 GHz Cores",
          Resolution: "2736 x 1824 pixels",
          GPU: "Intel HD 620",
          OS: "Windows 10 Professional (64-bit)",
        },
        link: "/laptops/Microsoft/6576dcb0281ec7002e2f01a0",
      },
      {
        id: "6576dcad281ec7002e2ef175",
        name: "Apple MacBook",
        image:
          "https://mainprojectimg.s3.amazonaws.com/128-Apple MacBook MNYJ2HNA Laptop.jpg",
        rating: 9.5,
        specs: {
          Core: "Hexa Core, 12 Threads",
          RAM: "8 GB DDR3",
          Storage: "512 GB",
          Speed: "2.6GHz 6-core 9th-generation Intel Core i7 processor",
          Resolution: "2304 x 1440 pixels",
          GPU: "Intel Integrated HD 615",
          OS: "Mac OS Sierra (64-bit)",
        },
        link: "/laptops/APPLE/6576dcad281ec7002e2ef175",
      },
      // Add more laptop items here...
    ],
    TABLETS: [
      {
        id: "6576e3f1f2378c94a9c99701",
        name: "Apple iPad Air 2022",
        image:
          "https://mainprojectimg.s3.amazonaws.com/20-Apple iPad Air 2022.jpg",
        rating: 8,
        specs: {
          CPU: "Octa-core (4x3.2 GHz & 4xX.X GHz)",
          GPU: "Apple GPU (8-core graphics)",
          storage: "64GB, 256GB 8GB RAM",
          Weight: "461 g (Wi-Fi) / 462 g (5G) (1.02 lb)",
          Resolution: "1640 x 2360 pixels (~264 ppi density)",
          Dimensions: "240 x 169.5 x 7.5 mm (9.45 x 6.67 x 0.30 in)",
        },
        link: "/tablets/Apple/6576e3f1f2378c94a9c99701",
      },
      {
        id: "6576e3f1f2378c94a9c996f6",
        name: "Apple iPad Pro 11 2022",
        image:
          "https://mainprojectimg.s3.amazonaws.com/9-Apple iPad Pro 11 2022.jpg",
        rating: 9,
        specs: {
          CPU: "Octa-core",
          GPU: "Apple GPU (10-core graphics)",
          storage:
            "128GB 8GB RAM, 256GB 8GB RAM, 512GB 8GB RAM, 1TB 16GB RAM, 2TB 16GB RAM",
          Weight: "466 g (Wi-Fi), 470 g (5G) (1.03 lb)",
          Resolution: "1668 x 2388 pixels (~265 ppi density)",
          Dimensions: "	247.6 x 178.5 x 5.9 mm (9.75 x 7.03 x 0.23 in)",
        },
        link: "/tablets/Apple/6576e3f1f2378c94a9c996f6",
      },
      {
        id: "661cd78d6dd8d95bd4c842d8",
        name: "Google Pixel Tablet",
        image:
          "https://mainprojectimg.s3.amazonaws.com/GooglePixelTablet8GBRAM256GB.jpg",
        rating: 7.8,
        specs: {
          CPU: "2.85 GHz, Octa Core Processor",
          GPU: "Mali-G710 MP7",
          storage: "256 GB",
          Weight: "493 g",
          Resolution: " 1600 x 2560 pixels",
          Dimensions: "169 x 258 x 8.1 mm",
        },
        link: "/tablets/Google/661cd78d6dd8d95bd4c842d8",
      },
      {
        id: "6576e3f1f2378c94a9c99702",
        name: "Apple iPad Air 2022",
        image:
          "https://mainprojectimg.s3.amazonaws.com/20-Apple iPad Air 2022.jpg",
        rating: 8,
        specs: {
          CPU: "Octa-core (4x3.2 GHz & 4xX.X GHz)",
          GPU: "Apple GPU (8-core graphics)",
          storage: "64GB, 256GB 8GB RAM",
          Weight: "461 g (Wi-Fi) / 462 g (5G) (1.02 lb)",
          Resolution: "1640 x 2360 pixels (~264 ppi density)",
          Dimensions: "iPadOS 15",
        },
        link: "/tablets/Apple/6576e3f1f2378c94a9c99702",
      },
      {
        id: "6576e3f0f2378c94a9c9941c",
        name: "Lenovo Yoga Tab 13",
        image:
          "https://mainprojectimg.s3.amazonaws.com/26-Lenovo Yoga Tab 13.jpg",
        rating: 8,
        specs: {
          CPU: "Octa-core (1x3.2 GHz Kryo 585 & 3x2.42 GHz Kryo 585 & 4x1.80 GHz Kryo 585)",
          GPU: "	Adreno 650",
          storage: "256GB 8GB RAM",
          Weight: "830 g (1.83 lb)",
          Resolution: "1350 x 2160 pixels, 16:10 ratio (~196 ppi density)",
          Dimensions: "293.4 mm x 204 mm x 6.2-24.9 mm",
        },
        link: "/tablets/Lenovo/6576e3f0f2378c94a9c9941c",
      },
      {
        id: "6576e3f2f2378c94a9c99cce",
        name: "Xiaomi Pad 6",
        image: "https://mainprojectimg.s3.amazonaws.com/26-Xiaomi Pad 6.jpg",
        rating: 7.3,
        specs: {
          CPU: "Octa-core (1x3.2 GHz Kryo 585 & 3x2.42 GHz Kryo 585 & 4x1.80 GHz Kryo 585)",
          GPU: "Adreno 650",
          storage: "128GB 6GB RAM, 128GB 8GB RAM, 256GB 8GB RAM",
          Weight: "490 g (1.08 lb)",
          Resolution: "1800 x 2880 pixels, 16:10 ratio (~309 ppi density)",
          Dimensions: "254 x 165.2 x 6.5 mm (10.0 x 6.50 x 0.26 in)",
        },
        link: "/tablets/Xiaomi/6576e3f2f2378c94a9c99cce",
      },
      {
        id: "6576e3f1f2378c94a9c99703",
        name: "Amazon Fire 7 2022 Tablet",
        image:
          "https://mainprojectimg.s3.amazonaws.com/AmazonFire72022Tablet.jpg",
        rating: 5,
        specs: {
          CPU: "2 GHz, Quad Core Processor",
          storage: "32 GB",
          Weight: "282 g",
          Resolution: "1024 x 600 pixels",
          Dimensions: "180.68 x 117.59 x 9.67 mm",
        },
        link: "/tablets/Apple/6576e3f1f2378c94a9c99703",
      },
      {
        id: "6576dcb0281ec7002e2f01c4",
        name: "Microsoft Surface Go 3 Laptop",
        image:
          "https://mainprojectimg.s3.amazonaws.com/100-Microsoft Surface Go 3 Laptop.jpg",
        rating: 6,
        specs: {
          CPU: "Intel Pentium Gold 6500Y",
          GPU: "Intel Integrated UHD Graphics 615",
          storage: "64 GB",
          Weight: "553 g",
          Resolution: "1920 x 1280 pixels",
          Dimensions:
            "Length: 278 mm (10.95”) Width: 206 mm (8.12”) Height: 15.7 mm (0.62”)",
        },
        link: "/laptops/Microsoft/6576dcb0281ec7002e2f01c4",
      },
      {
        id: "6576e3eff2378c94a9c98c93",
        name: "OnePlus Pad",
        image: "https://mainprojectimg.s3.amazonaws.com/9-OnePlus Pad.jpg",
        rating: 8,
        specs: {
          CPU: "Octa-core (1x3.05 GHz Cortex-X2 & 3x2.85 GHz Cortex-A710 & 4x1.80 GHz Cortex-A510)",
          GPU: "Mali-G710 MC10",
          storage: "128GB 8GB RAM, 256GB 12GB RAM",
          Weight: "552 g (1.22 lb)",
          Resolution: "2000 x 2800 pixels (~296 ppi density)",
          Dimensions: "258 x 189.4 x 6.5 mm (10.16 x 7.46 x 0.26 in)",
        },
        link: "/tablets/OnePlus/6576e3eff2378c94a9c98c93",
      },
      {
        id: "6576e3f1f2378c94a9c99704",
        name: "Samsung Galaxy Tab S9 Ultra",
        image:
          "https://mainprojectimg.s3.amazonaws.com/11-Samsung Galaxy Tab S9 Ultra.jpg",
        rating: 9.5,
        specs: {
          CPU: "Octa-core (1x3.36 GHz Cortex-X3 & 2x2.8 GHz Cortex-A715 & 2x2.8 GHz Cortex-A710 & 3x2.0 GHz Cortex-A510)",
          GPU: "	Adreno 740",
          storage: "256GB 12GB RAM, 512GB 12GB RAM, 1TB 12GB RAM, 1TB 16GB RAM",
          Weight: "732 g (1.61 lb)",
          Resolution: "1848 x 2960 pixels, 16:10 ratio (~239 ppi density)",
          Dimensions: "326.4 x 208.6 x 5.5 mm (12.85 x 8.21 x 0.22 in)",
        },
        link: "/tablets/Apple/6576e3f1f2378c94a9c99704",
      },
      // Add more tablet items here...
    ],
  };

  const items = topItems[formattedCategory] || [];

  return (
    <div>
      <h1
        style={{
          fontSize: "30px",
          fontFamily: "Poppins, sans-serif",
          color: "#333",
          textTransform: "uppercase",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          letterSpacing: "1.5px",
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
          padding: "10px 0",
          borderBottom: "3px solid #0056b3",
          transition: "all 0.3s ease-in-out",
        }}
      >
        TRENDING {formattedCategory}
      </h1>
      <div className="container mt-5">
        {items.length > 0 ? (
          items.map((item) => (
            <Link
              key={item.id}
              to={item.link}
              style={{ textDecoration: "none" }}
            >
              <div className="product-card1">
                <div className="product-image1">
                  <img src={item.image} alt="Product Image" />
                </div>
                <div className="product-content">
                  <div className="product-info">
                    <div className="product-score">
                      <span className="product-score-value">{item.rating}</span>
                      <span className="product-score-label">/10</span>
                    </div>
                    <h2 className="mb-2">{item.name}</h2>
                  </div>
                  <ul>
                    {Object.entries(item.specs).map(([key, value]) => (
                      <li key={key}>
                        <FontAwesomeIcon icon={faCheck} className="ticon" />
                        <span>
                          {formatKey(key)}: {value}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <div className="product-actions">
                    <button>Compare</button>
                    <button>Like</button>
                  </div>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p>No items found for this category.</p>
        )}
      </div>
    </div>
  );
};

const formatKey = (key) => {
  // Insert spaces before capital letters
  return key.replace(/([A-Z])/g, " $1").trim();
};

export default TrendingDetails;
