import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSingleProductData } from "../../Service/Api";
import { addToWishlist } from "../../Service/wishlistService";
import ReviewModal from "../../Components/Comment/ReviewModal";
import { toast, ToastContainer } from "react-toastify";
import FAQ from "../FAQ.jsx"; // Update the path based on the location of your FAQ component
import UsageTips from "../UsageTips.jsx"; // Import the UsageTips component
import PrinterCompatibility from "../PrinterCompatibilty.jsx";
import { faqs, usageTips, categoryData } from "../SpecificationData.js";
import { addToCompare } from "../../Feature/store/compareActions.js";
import ComparisonPopup from "../../Feature/Compare/ComparisonPopup.jsx";
import Pros from "./Pros&cons/Pros.js";
import TroubleSection from "../../Components/Troubleshoot/TroubleSection.js";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { category: rawCategory, brand, _id } = useParams();
  const category = rawCategory ? rawCategory.toUpperCase() : "";
  const acceptableBrands = ["XEROX", "BROTHER", "EPSON", "CANON", "HP"];

  const [data, setData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const compareItems = useSelector(
    (state) => state.compare?.compareItems || []
  );

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const responseData = await getSingleProductData(category, brand, _id);

        if (responseData && responseData.status === "success") {
          setData(responseData.data);
        } else {
          console.error("Error fetching data:", responseData.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataFromApi();
  }, [category, brand, _id]);

  const closePopup = () => {
    setShowPopup(false);
  };

  const selectedItem = data;
  const properties = Object.keys(selectedItem || {}).filter(
    (property) =>
      ![
        "_id",
        "img",
        "manufacturer",
        "country_of_origin",
        "brand",
        "category",
        "pros",
        "cons",
        "updatedAt",
        "price",
        "3",
        "createdAt",
      ].includes(property)
  );

  function formatKeyCapitalkey(key) {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  }

  async function handleAddToWishlist(_id) {
    try {
      if (!_id) {
        console.error("Missing _id parameter");
        return;
      }
      const token = localStorage.getItem("accessToken");
      if (!token) {
        navigate("/login");
        return;
      }
      const response = await addToWishlist(token, _id);
      if (!response) {
        navigate("/login");
      }
      if (response.success === false) {
        toast.warn("Login To Add To favourite", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (response.statusCode === 409) {
        toast.warn(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (response.statusCode === 200) {
        toast.success(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
    }
  }

  const handleCompare = () => {
    setShowPopup(true);
    const isItemInCompare = compareItems.some((item) => item._id === data._id);

    if (!isItemInCompare) {
      dispatch(addToCompare(data));
    } else {
      toast.info("Item is already in the comparison list.");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const findCategoryByCategoryAndBrand = (categoryData, category, brand) => {
    return categoryData.find(
      (item) => item.category === category && item.brand === brand
    );
  };

  const categoryInfo = findCategoryByCategoryAndBrand(
    categoryData,
    category,
    brand
  );

  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7"></div>
        {data && (
          <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
            <div className="h-full xl:col-span-2">
              <img
                className="spec-img w-full h-full rounded-md"
                src={data.img}
                alt={data.img}
              />
            </div>
            <div className="text-xl font-semibold h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6 justify-center">
              {data && <h1>{data.model_name}</h1>}
              <hr />
              <div className="row mt-5">
                <div className="col-6 ">
                  <button
                    className="compare-btn w-100"
                    onClick={() => handleCompare(data)}
                  >
                    Compare
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="w-100 wishlist-btn"
                    onClick={() => handleAddToWishlist(_id)}
                  >
                    Favourite
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <div className="space-x-4 pt-4">
            {selectedItem && (
              <div className="">
                <div className="">
                  <div className="">
                    <div className="">
                      <div className="">
                        <table className="dtl-table table-hover table-responsive-sm">
                          <thead style={{ fontSize: "2rem" }}>
                            <tr>
                              <th scope="col" style={{ color: "#0a6075" }}>
                                Title
                              </th>
                              <th scope="col" style={{ color: "#0a6075" }}>
                                Description
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {properties.map((property) => {
                              const value = selectedItem[property];

                              if (
                                !value ||
                                (typeof value === "object" &&
                                  Object.keys(value).length === 0)
                              ) {
                                return null;
                              }

                              return (
                                <tr key={property}>
                                  <td scope="row">
                                    {formatKeyCapitalkey(property)}
                                  </td>
                                  <td scope="row" className="hello">
                                    {typeof value === "object" ? (
                                      <div>
                                        {Object.entries(value).map(
                                          ([key, val]) => (
                                            <p key={key}>
                                              <strong>
                                                {formatKeyCapitalkey(key)}:
                                              </strong>
                                              {typeof val === "object"
                                                ? JSON.stringify(val)
                                                : val}
                                            </p>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      value
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="my-4"></div>
        </div>
      </div>
      <div>
        {data &&
          category === "PRINTER" &&
          acceptableBrands.includes(brand.toUpperCase()) && (
            <TroubleSection
              name={data.model_name}
              img={data.img}
              id={_id}
              category={category}
              brand={brand}
            />
          )}
      </div>
      <div className="p-4">
        <h3 className="text-center mb-3 pros-text">
          <i className="bi bi-check-lg pros-tick mt-2"></i> Pros & Cons
          <i className="bi bi-x pros-close"></i>
        </h3>
        <Pros category={category} brand={brand} _id={_id} />
      </div>
      <FAQ category={category} faqs={faqs} />
      {usageTips.map((tipCategory) =>
        tipCategory.category === category ? (
          <UsageTips key={category} tips={tipCategory.tips} />
        ) : null
      )}
      {categoryInfo && (
        <PrinterCompatibility
          category={category}
          brand={brand}
          categoryData={categoryData}
        />
      )}
      <ReviewModal productId={_id} />
      <ToastContainer />
      {showPopup && <ComparisonPopup popup={closePopup} />}
    </div>
  );
};

export default ProductDetails;
