import React, { useEffect, useState } from "react";

import { getWishlist, removeFromWishlist } from "../../Service/wishlistService";
import { Link, useNavigate } from "react-router-dom";

const Wishlist = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        navigate("/login");
        return;
      }
      const response = await getWishlist(token);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Favourite:", error);
      setLoading(false);
    }
  };

  const handleRemove = async (_id) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("accessToken");
      if (!token) {
        navigate("/login");
        return;
      }
      await removeFromWishlist(_id, token);

      setData((prevData) => prevData.filter((item) => item._id !== _id));
      setLoading(false);
    } catch (error) {
      console.error("Error removing from Favourite:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    fetchData();
  }, []);

  const WishlistItem = ({ item }) => {
    return (
      <div className="wishlist-item p-5">
        <img src={item.img} alt={item.img} />
        <h3>{item.model_name}</h3>
        <button onClick={() => handleRemove(item._id)}>Remove</button>
      </div>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <div className="wishlist mb-5">
        {data.length === 0 ? (
          <div className="no-items-message">
            <h1>No items in the Favourite</h1>
            <p>
              <Link to={"/"} className="button-no-items-message">
                Add Your favorate Product
              </Link>
            </p>
          </div>
        ) : (
          data.map((item) => <WishlistItem key={item._id} item={item} />)
        )}
      </div>
    </div>
  );
};

export default Wishlist;
