import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  adminUpdateNews,
  adminReadSingleNews,
} from "../../Service/admin-service";

export default function AdminNewsUpdate({ onUpdate }) {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    img: "",
  });
  const navigate = useNavigate();
  const { _id } = useParams();

  useEffect(() => {
    const loadPrevData = async () => {
      try {
        const response = await adminReadSingleNews(_id);
        setFormData(response);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };
    loadPrevData();
  }, [_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const confirmUpdate = window.confirm(
      "Are you sure you want to update this news?"
    );

    if (confirmUpdate) {
      try {
        const response = await adminUpdateNews(_id, formData);
        alert(response.message);
        navigate(`/admin/news`);
        onUpdate();
      } catch (error) {
        console.error("Error updating news:", error);
      }
    }
  };

  return (
    <div className="container">
      <h2>Update News</h2>
      <form onSubmit={handleUpdate}>
        <div className="form-group">
          <label>Title:</label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Description:</label>
          <textarea
            className="form-control"
            name="description"
            value={formData.description}
            onChange={handleInputChange}></textarea>
        </div>
        <div className="form-group">
          <label>Image URL:</label>
          <input
            type="text"
            className="form-control"
            name="img"
            value={formData.img}
            onChange={handleInputChange}
          />
        </div>

        <button type="submit" className="btn btn-success">
          Update News
        </button>
      </form>
    </div>
  );
}
