import React, { useState, useEffect, useRef } from "react";

const Slider = () => {
  const [sliderCounter, setSliderCounter] = useState(0);
  const sliderContent = [
    "SMART TV",
    "SMART PHONES",
    "TABLETS",
    "ROUTER",
    "PRINTER",
    "EARPHONES",
    "EXTENDERS",
    "LAPTOPS",
    "GRAPHIC CARD",
  ];
  const sliderValueRef = useRef(null);

  useEffect(() => {
    const slide = () => {
      if (sliderCounter === sliderContent.length - 1) {
        setSliderCounter(0);
      } else {
        setSliderCounter((prevCounter) => prevCounter + 1);
      }
    };

    const intervalId = setInterval(slide, 4000);

    return () => clearInterval(intervalId);
  }, [sliderCounter]);

  useEffect(() => {
    const sliderValue = sliderValueRef.current;
    sliderValue.innerHTML = "";

    sliderValue.classList.remove("holder-animation");
    void sliderValue.offsetWidth;
    sliderValue.classList.add("holder-animation");

    for (let i = 0; i < sliderContent[sliderCounter].length; i++) {
      let letterDiv = document.createElement("div");
      letterDiv.innerHTML = sliderContent[sliderCounter][i];

      if (letterDiv.innerHTML === " ") {
        letterDiv.innerHTML = "&nbsp;";
      }
      letterDiv.classList.add("start");
      letterDiv.classList.add("animation");
      letterDiv.style.animationDelay = i / 10 + "s";
      sliderValue.appendChild(letterDiv);
    }
  }, [sliderCounter]);

  return (
    <div id="slider">
      <div id="sliderValue" ref={sliderValueRef}></div>
    </div>
  );
};

export default Slider;
