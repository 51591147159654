import React from "react";

const NewsCard = ({ article }) => {
  return (
    <div className="news-card">
      <img
        src={article.img}
        alt={article.title}
        className="news-image"
        style={{ padding: "10px" }}
      />
      <div className="news-info">
        <h3 className="news-title News-h">{article.title}</h3>
        <p className="news-summary">
          {article.description.slice(0, 300)}
          {article.description.length > 300 ? "..." : ""}
        </p>

        <p className="news-meta">
          {article.createdAt}
          {/* | By {article.author} */}
        </p>
      </div>
    </div>
  );
};

export default NewsCard;
