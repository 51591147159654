import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
import Image from "../../DesignLayouts/Image";
import Flex from "../../DesignLayouts/Flex";
import { FaUser, FaCaretDown } from "react-icons/fa";
import { BsSuitHeartFill } from "react-icons/bs";
import { logoutUser } from "../../../Service/userService";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Header = () => {
  const navBarList = [
    {
      _id: 1006,
      title: "Trending",
      link: "/trending",
    },
    {
      _id: 1001,
      title: "Compare",
      link: "/comparison",
    },
    {
      _id: 1002,
      title: "News",
      link: "/News",
    },
    {
      _id: 1004,
      title: "Chasms",
      link: "/Coming",
    },
  ];

  const isLoggedIn = !!localStorage.getItem("accessToken");
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await logoutUser(token);
      if (response.success === true) {
        toast.warn(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("accessToken");
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  const [showMenu, setShowMenu] = useState(true);
  const [sidenav, setSidenav] = useState(false);
  const [showUser, setShowUser] = useState(false);

  // Ref for the side navigation
  const sidenavRef = useRef();

  // Effect for handling clicks outside the sidenav
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidenavRef.current && !sidenavRef.current.contains(event.target)) {
        setSidenav(false); // Close the sidenav
      }
    };

    // Only add the event listener when the sidenav is open
    if (sidenav) {
      document.addEventListener("click", handleClickOutside);
    }

    // Cleanup to remove the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sidenav]); // Dependency on sidenav state

  const location = useLocation();
  useEffect(() => {
    const ResponsiveMenu = () => {
      if (window.innerWidth < 667) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };
    ResponsiveMenu();
    window.addEventListener("resize", ResponsiveMenu);
  }, []);

  const categoriesData = [
    {
      category: "MOBILES",
    },
    {
      category: "SMART WATCH",
    },
    {
      category: "ROUTER",
    },
    {
      category: "PRINTER",
    },
    {
      category: "EARPHONES",
    },
    {
      category: "TABLETS",
    },
    {
      category: "EXTENDER",
    },
    {
      category: "GRAPHIC CARD",
    },
    {
      category: "MOTHERBOARD",
    },
    {
      category: "LAPTOPS",
    },
    {
      category: "PROCESSORS",
    },
    {
      category: "COMPUTERS",
    },
    {
      category: "MOUSES",
    },
    {
      category: "SMART GLASS",
    },
    {
      category: "GAMING CONSOLES",
    },
    {
      category: "SPEAKERS",
    },
    {
      category: "VR HEADSETS",
    },
    {
      category: "KEYBOARD",
    },
    {
      category: "FIREWALLS",
    },
    {
      category: "SMART TV",
    },

    // Add more categories and brands as needed
  ];

  const [categories, setCategories] = useState(categoriesData);
  const toggleCategory = (index) => {
    const updatedCategories = [...categories];
    updatedCategories[index].open = !updatedCategories[index].open;
    setCategories(updatedCategories);
  };

  return (
    <div className="w-full h-20 bg-white sticky top-0 z-50 border-b-[1px] border-b-gray-200">
      <nav className="h-full px-4 max-w-container mx-auto relative">
        <Flex className="flex items-center justify-between h-full pb-5 pt-5 ">
          <Link to="/">
            <div>
              <Image
                className="logo w-32 object-cover mb-3 "
                imgSrc={"https://mainprojectimg.s3.amazonaws.com/logo2.png"}
              />
            </div>
          </Link>
          <div>
            <div
              className="profile flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative"
              style={{ display: "none" }}
            >
              <div onClick={() => setShowUser(!showUser)} className="flex">
                <FaUser />
                <FaCaretDown />
              </div>
              {showUser && (
                <motion.ul
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="absolute top-6 left-0 z-50 bg-primeColor w-44 text-[#767676] h-auto p-4 pb-6"
                >
                  {isLoggedIn ? (
                    <li
                      className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer"
                      onClick={handleLogout}
                    >
                      Logout
                    </li>
                  ) : (
                    <>
                      <Link to="/login">
                        <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                          Login
                        </li>
                      </Link>
                      <Link onClick={() => setShowUser(false)} to="/signup">
                        <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                          Sign Up
                        </li>
                      </Link>
                    </>
                  )}

                  <Link
                    to={"/profile"}
                    className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer"
                  >
                    Profile
                  </Link>
                </motion.ul>
              )}

              <Link to={"/favourite"}>
                <BsSuitHeartFill />
              </Link>
            </div>
            {showMenu && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="flex items-center w-auto z-50 p-0 gap-2"
              >
                <>
                  {navBarList.map(({ _id, title, link }) => (
                    <NavLink
                      key={_id}
                      className="flex font-normal hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-[#767676] hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0 active"
                      to={link}
                      state={{ data: location.pathname.split("/")[1] }}
                    >
                      {title}
                    </NavLink>
                  ))}
                </>
              </motion.ul>
            )}
            <HiMenuAlt2
              onClick={(event) => {
                event.stopPropagation(); // Stop click event from propagating to document
                setSidenav(!sidenav);
              }}
              className="inline-block md:hidden cursor-pointer w-8 h-6 absolute top-6 right-4"
            />

            {sidenav && (
              <div className="fixed top-0 left-0 w-full h-screen bg-black text-gray-200 bg-opacity-80 z-50">
                <motion.div
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="w-[80%] h-full relative"
                  ref={sidenavRef} // Attach the ref here
                >
                  <div className="w-full h-full bg-primeColor p-6">
                    <img
                      className="w-28 mb-6"
                      src={"https://mainprojectimg.s3.amazonaws.com/logo2.png"}
                      alt="logoLight"
                    />
                    <ul className="text-gray-200 flex flex-col gap-2">
                      {navBarList?.map((item) => (
                        <li
                          className="font-normal hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                          key={item._id}
                        >
                          <NavLink
                            to={item.link}
                            state={{ data: location.pathname.split("/")[1] }}
                            onClick={() => setSidenav(false)}
                          >
                            {item.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                    <div>
                      {categories?.map((categoryData, index) => (
                        <div key={index} className="mt-4">
                          <Link
                            to={`${categoryData.category}`}
                            onClick={() => setSidenav(false)}
                          >
                            <h1
                              onClick={() => toggleCategory(index)}
                              className="flex justify-between text-base cursor-pointer items-center font-titleFont mb-2"
                            >
                              {categoryData.category}
                              <span className="text-lg">
                                {categoryData.open ? "-" : "+"}
                              </span>
                            </h1>
                          </Link>
                          {categoryData.open && (
                            <motion.ul
                              initial={{ y: 15, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              transition={{ duration: 0.4 }}
                              className="text-sm flex flex-col gap-1"
                            >
                              {categoryData?.brands?.map(
                                (brand, brandIndex) => (
                                  <Link
                                    to={`${categoryData.category}/${brand}`}
                                    onClick={() => setSidenav(false)}
                                  >
                                    <li
                                      key={brandIndex}
                                      className="headerSedenavLi"
                                    >
                                      {brand}
                                    </li>
                                  </Link>
                                )
                              )}
                            </motion.ul>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <span
                    onClick={() => setSidenav(false)}
                    className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 -right-10 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
                  >
                    <MdClose />
                  </span>
                </motion.div>
              </div>
            )}
          </div>
        </Flex>
      </nav>
      <ToastContainer />
    </div>
  );
};

export default Header;
