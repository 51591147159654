import React, { useEffect, useContext, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "../Components/PageProps/Breadcrumbs";
import ProductBanner from "../Components/PageProps/ShopPage/ProductBanner";
import ShopSideNav from "../Components/PageProps/ShopPage/ShopSideNav";
import { ProductContext } from "../Context/ProductContext";

const Shop = () => {
  const { category, brand } = useParams();
  const { products, loading, error, updateProducts } =
    useContext(ProductContext);
  const navigate = useNavigate();
  const [itemsToShow, setItemsToShow] = useState(20); // Initial number of items to show

  useEffect(() => {
    updateProducts({ category, brand });

    window.scroll(0, 0);
  }, [category, brand, updateProducts]);

  const onClickBtn = (category, brand, _id) => {
    navigate(`/${category}/${brand}/${_id}`);
  };

  const excludeBasedOnModelName = useMemo(() => {
    return (modelName, category) => {
      const modelNameLower = modelName.toLowerCase();

      if (category === "KEYBOARD" && modelNameLower.includes("laptop")) {
        return true; // Exclude KEYBOARD intended for laptops
      } else if (
        category === "MOBILES" &&
        (modelNameLower.includes("watch") || modelNameLower.includes("pad"))
      ) {
        return true; // Exclude products with "watch" or "pad" in the model name for MOBILES category
      } else if (category === "MOUSES" && modelNameLower.includes("monitor")) {
        return true; // Exclude models containing "monitor" for MOUSES category
      } else if (category === "MOUSES" && modelNameLower.includes("laptop")) {
        return true; // Exclude models containing "laptop" for MOUSES category
      }

      return false; // Default to include this product
    };
  }, []);

  const uniqueProducts = useMemo(() => {
    if (!products) return []; // Return an empty array if products is not defined

    return products.filter(
      (product, index, self) =>
        index === self.findIndex((t) => t.model_name === product.model_name) &&
        !excludeBasedOnModelName(product.model_name, category)
    );
  }, [products, excludeBasedOnModelName, category]);

  const displayedProducts = useMemo(
    () => uniqueProducts.slice(0, itemsToShow),
    [uniqueProducts, itemsToShow]
  );

  const handleShowMore = () => {
    setItemsToShow((prevItemsToShow) => prevItemsToShow + 20);
  };

  return (
    <div className="   max-w-container mx-auto px-4">
      <div className="col-12 row">
        <div className="col-lg-4 col-md-4 col-sm-12">
          <Breadcrumbs title="Products" />
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 text-center mt-4 Heading-text ">
          <h1 className="mt-4 p-3 prd-hed " style={{ color: "#FFF" }}>
            {brand.toUpperCase()} {category.toUpperCase()}
          </h1>
        </div>
      </div>

      <div className="w-full h-full flex pb-20 gap-10">
        <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex h-full">
          <ShopSideNav category={category} brand={brand} />
        </div>
        <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10">
          <ProductBanner />
          {loading && <div>Loading...</div>}
          {error && <div>Error: {error}</div>}
          <div className="grid grid-cols-4 gap-4">
            {displayedProducts.map((product) => (
              <div
                key={product._id}
                className="w-full relative group"
                onClick={() =>
                  onClickBtn(product.category, product.brand, product._id)
                }
              >
                <div className="max-w-80 max-h-80 relative overflow-y-hidden">
                  <img
                    src={product.img}
                    alt={product.model_name}
                    className="img-product w-full h-full "
                  />
                  <div className="w-full h-32 absolute bg-white -bottom-[130px] group-hover:bottom-0 duration-700">
                    <ul className="w-full h-full flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
                      <li className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full">
                        Compare
                        {/* Add compare icon here */}
                      </li>
                      <li className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full">
                        View Details
                        {/* Add view details icon here */}
                      </li>
                      <li className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full">
                        Add to Favourite
                        {/* Add wishlist icon here */}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
                  <h2 className="hello-rev product-name text-primeColor font-bold">
                    {product.model_name}
                  </h2>
                  {/* Placeholder for additional product info */}
                </div>
              </div>
            ))}
          </div>
          {uniqueProducts.length > 20 &&
            itemsToShow < uniqueProducts.length && (
              <div className="text-center mt-2 mb-4">
                <button className="btn load" onClick={handleShowMore}>
                  Show More <i className="fa fa-angle-down"></i>
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Shop;
