import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminProfile } from "../../Service/adminService";
import Sidebar from "../../Admin/Dashboard/Sidebar";
import Admintitle from "../../Admin/Dashboard/AdminTitle";

export default function AdminProfile() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetchProfile = async () => {
      try {
        const accessToken = localStorage.getItem("adminaccessToken");

        if (!accessToken) {
          navigate("/admin/auth");
          return;
        }

        const response = await adminProfile(accessToken);

        if (response.success === true) {
          setProfileData(response.data);
        } else {
          navigate("/admin/auth");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setError("An error occurred while fetching profile.");
      }
    };

    fetchProfile();
  }, [navigate]);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-3">
            <Sidebar />
          </div>
          <div className="col-9">
            <Admintitle />
            <div className="flex w-100 container overflow-auto">
              {
                <main className="w-100 bg-white shadow-lg overflow-auto rounded">
                  <div
                    id="header-actions"
                    className="bg-white flex items-center justify-between p-4 sticky top-0">
                    <h1 className="font-semibold invisible text-grey-900">
                      {profileData?.fullName}
                    </h1>
                    {/* <i className="material-icons text-gray-600">edit</i> */}
                  </div>
                  <div
                    id="user-header"
                    className="bg-white flex items-center p-4">
                    <img
                      src="https://www.thewrap.com/wp-content/uploads/2018/08/doug-judy-craig-robinson.jpg"
                      alt=""
                      className="h-24 mr-6 rounded-full w-24 object-cover"
                    />
                    <div className="flex flex-col flex-1">
                      <h2 className="font-semibold mb-2 text-xl">
                        {profileData?.fullName}
                      </h2>
                      <p className="font-semibold mb-2 text-xl profile_email">
                        {profileData?.email}
                      </p>
                      <div className="grid grid-cols-3 text-gray-700">
                        <div className="flex flex-col items-center justify-center p-1"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="tabs"
                    className="bg-white border-b border-gray-200 flex sticky"
                    style={{ top: "3rem" }}>
                    <div className="border-b-4 border-transparent cursor-pointer flex-1 p-2 text-center text-sm active">
                      Your Details
                    </div>
                  </div>
                  <section className="border-b-8 border-gray-200 py-2">
                    <div className="flex hover:bg-gray-100 items-center px-4 py-2">
                      <i
                        className=" h-10 inline-block mr-4 p-1 text-gray-600 w-10 bi bi-person-circle "
                        style={{ fontSize: "25px" }}></i>
                      Edit Profile
                    </div>
                    <div className="flex hover:bg-gray-100 items-center px-4 py-2">
                      <i
                        className="h-10 inline-block mr-4 p-1 text-gray-600 w-10 bi bi-bookmark-heart "
                        style={{ fontSize: "25px" }}></i>
                      Your Wishlist
                    </div>
                    <div className="flex hover:bg-gray-100 items-center px-4 py-2">
                      <i
                        className="h-10 inline-block mr-4 p-1 text-gray-600 w-10 bi bi-envelope-arrow-down-fill "
                        style={{ fontSize: "25px" }}></i>
                      Your Email
                    </div>
                    <div className="flex hover:bg-gray-100 items-center px-4 py-2">
                      <i
                        className="h-10 inline-block mr-4 p-1 text-gray-600 w-10 bi bi-key-fill "
                        style={{ fontSize: "25px" }}></i>
                      Change Password
                    </div>
                  </section>
                </main>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
