// rootReducer.js
import { combineReducers } from "redux";
import compareReducer from "./compareReducer";

const rootReducer = combineReducers({
  compare: compareReducer,
  // other reducers...
});

export default rootReducer;
