import React, { useState, useEffect, useRef } from "react";
import Slider from "./Slider";

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const searchResultRef = useRef(); // Ref for the search result container

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        searchResultRef.current &&
        !searchResultRef.current.contains(e.target)
      ) {
        setSearchQuery(""); // Reset search query to close the container
        setSuggestions([]); // Clear suggestions
      }
    };

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="hero-container">
      <div className="text-section">
        <h1>Welcome to Gadgetfobia,</h1>
        <h2 style={{ color: "#008080" }}>Every Gadget Tells a Story</h2>

        <div id="slider">
          <div className="container" style={{ marginLeft: "-29px" }}>
            <div className="col-12 row">
              <div className=" col-lg-4 col-md-4 col-sm-12">
                {" "}
                <b>FIND YOUR IDEAL</b>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12 cg-banner">
                {" "}
                <Slider />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="search-box">
          <input
            type="text"
            placeholder="Find Yours..."
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
          /> */}
        {/* Make sure this div correctly wraps the suggestions */}
        {/* <div ref={searchResultRef}>
            {suggestions.length > 0 && (
              <ul className="search-suggestions">
                {suggestions.map((suggestion, index) => (
                  <li
                    className="d-flex justify-center"
                    style={{
                      fontWeight: "800",
                      padding: "20px",
                      backgroundColor:
                        "rgb(243 244 246 / var(--tw-bg-opacity));",
                    }}
                    key={index}
                    onClick={() =>
                      navigate(`/searchresult?query=${suggestion}`)
                    }
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div> */}

        {/* </div> */}
      </div>
      <div className="image-section text-center">
        <img
          src={"https://mainprojectimg.s3.amazonaws.com/img1.png"}
          alt="Stack of books"
          className="books-img"
        />
      </div>
    </div>
  );
};

export default SearchBar;
