import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { getBrandData } from "../../../Service/Api"; // Import your API call function

// FontAwesome icons
import {
  faTv,
  faMobileAlt,
  faLaptop,
  faTabletAlt,
  faWifi,
  faPrint,
  faHeadphones,
  faExpandArrowsAlt,
  faMemory,
  faMicrochip,
  faDesktop,
  faMouse,
  faGlasses,
  faGamepad,
  faVolumeUp,
  faVrCardboard,
  faKeyboard,
  fas,
} from "@fortawesome/free-solid-svg-icons";

// Dynamic categories with associated icons and class names (removed static images)
const categoriesConfig = {
  smartTv: {
    icon: faTv,
    name: "SMART TV",
    className: "smart-tv",
  },
  mobiles: {
    icon: faMobileAlt,
    name: "MOBILES",
    className: "Mobiles",
  },
  tablets: {
    icon: faTabletAlt,
    name: "TABLETS",
    className: "Tablets",
  },
  routers: {
    icon: faWifi,
    name: "ROUTER",
    className: "Routers",
  },
  printers: {
    icon: faPrint,
    name: "PRINTER",
    className: "Printers",
  },
  earbuds: {
    icon: faHeadphones,
    name: "EARPHONES",
    className: "Earbuds",
  },
  extenders: {
    icon: faExpandArrowsAlt,
    name: "EXTENDERS",
    className: "Extenders",
  },
  laptop: {
    icon: faLaptop,
    name: "LAPTOPS",
    className: "Laptops",
  },
  graphicCards: {
    icon: faMemory,
    name: "GRAPHIC CARD",
    className: "Graphic",
  },
  motherboards: {
    icon: faMicrochip,
    name: "MOTHERBOARD",
    className: "Graphic",
  },
  processor: {
    icon: faDesktop,
    name: "PROCESSORS",
    className: "Extenders",
  },
  computers: {
    icon: faDesktop,
    name: "COMPUTERS",
    className: "Earbuds",
  },
  mouses: {
    icon: faMouse,
    name: "MOUSES",
    className: "Printers",
  },
  smartglasses: {
    icon: faGlasses,
    name: "SMART GLASS",
    className: "Routers",
  },
  gamingConsoles: {
    icon: faGamepad,
    name: "GAMING CONSOLES",
    className: "Tablets",
  },
  speakers: {
    icon: faVolumeUp,
    name: "SPEAKERS",
    className: "Laptops",
  },
  vrHeadsets: {
    icon: faVrCardboard,
    name: "VR HEADSETS",
    className: "Mobiles",
  },
  KEYBOARD: {
    icon: faKeyboard,
    name: "KEYBOARD",
    className: "smart-tv",
  },
  smartWatches: {
    icon: faKeyboard,
    name: "SMART WATCH",
    className: "Printers",
  },
  firewall: {
    icon: faKeyboard,
    name: "FIREWALLS",
    className: "Graphic",
  },

  // Add other categories similarly...
};

const MyList = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [iconData, setIconData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCategory) {
      fetchBrandData(selectedCategory);
    }
  }, [selectedCategory]);

  const fetchBrandData = async (categoryKey) => {
    try {
      const category = categoriesConfig[categoryKey]?.name;
      if (category) {
        const data = await getBrandData(category);
        if (data.success === true) {
          setIconData(data.data);
        } else {
          console.error("Failed to fetch data");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const visibleCategories = Object.entries(categoriesConfig).slice(
    0,
    isVisible ? undefined : 9
  );

  // Function to handle category click
  const handleCategoryClick = (categoryKey) => {
    const categoryRoute = `/${categoriesConfig[categoryKey]?.name}`;
    navigate(categoryRoute); // Redirect to the category page
  };

  return (
    <div className="p-5">
      <h3 className="p-3 Heading-text">What do you want to Explore today?</h3>
      <div className="my-list-container mb-3 mt-4">
        {visibleCategories.map(([key, { icon, name, className }]) => (
          <div
            key={key}
            className={`my-list-item ${className}`}
            onClick={() => handleCategoryClick(key)}
          >
            <FontAwesomeIcon icon={icon} />
            <span className="ml-3">{name}</span>
          </div>
        ))}
      </div>
      <hr />
      <button className="p-3 view " onClick={() => setIsVisible(!isVisible)}>
        {isVisible ? "View Less" : "View More"}
      </button>
      <hr />
    </div>
  );
};

export default MyList;
