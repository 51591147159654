import api from "../Service/apiHelper";

export const getBlog = async () => {
  try {
    const response = await api.get(`api/v1/blog/get-blog`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      throw new Error("Error in Logging User. Please try again later.");
    }
  }
};

export const getBlogByKeyword = async (_id) => {
  try {
    const response = await api.get(`api/v1/blog/get-blog/${_id}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      throw new Error("Error in Logging User. Please try again later.");
    }
  }
};
