import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "../src/Asset/Css/admin.css";
import "slick-carousel/slick/slick.css";
import "./index.css";
import "../src/Asset/Css/user.css";
import { Toaster } from "react-hot-toast";
import App from "./App";
import { ProductProvider } from "./Context/ProductContext";
import { TopBrandProvider } from "./Context/TopBrandContext";
import { store as reduxToolkitStore, persistor } from "./redux/store";
import store1 from "./Feature/store/store";
import { AuthProvider } from "./Context/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider store={reduxToolkitStore}>
    <PersistGate loading={null} persistor={persistor}>
      <TopBrandProvider>
        <ProductProvider>
          <AuthProvider>
            <ReduxProvider store={store1}>
              <Toaster />
              <App />
            </ReduxProvider>
          </AuthProvider>
        </ProductProvider>
      </TopBrandProvider>
    </PersistGate>
  </ReduxProvider>
);
