import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import SmallCard from "./SmallCard";
import AdminTitle from "./AdminTitle";
import { Link, useParams } from "react-router-dom";
import { adminBrand } from "../../Service/admin-service";

export default function Brand() {
  const [data, setData] = useState([]);
  const [BrandCount, setBrandCount] = useState([]);
  const { category } = useParams();
  const getData = async () => {
    try {
      const response = await adminBrand(category);
      response.brandCounts.sort((a, b) =>
        (a.brand || "").localeCompare(b.brand || "")
      );
      setData(response.brandCounts);
      setBrandCount(response.totalBrandCount);
    } catch (error) {}
  };

  const handleAddCategory = (newCategory) => {
    console.log(newCategory);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          <AdminTitle
            title1={"Admin Brand"}
            title2={`Total Brand ${BrandCount}`}
          />

          <div className="row" onClick={() => handleAddCategory}>
            <div className="col-lg-2 col-md-6 mb-4 m-3">
              <SmallCard title={"Add New Brand"} onAdd={handleAddCategory} />
            </div>
            {data?.map((item, index) => (
              <div key={index} className="col-lg-2 col-md-6 mb-4 m-3">
                <Link to={`/admin/${category}/${item.brand}`}>
                  <SmallCard title={item.brand} content={item.count} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
