import React from "react";

const CustomCard = ({
  _id,
  title,
  description,
  img,
  date,
  onUpdate,
  onDelete,
}) => {
  return (
    <div className="col-lg-3 col-md-6 mb-4">
      <div className="card h-100 custom-card" data-card-id={_id}>
        <img className="card-img-top" src={img} alt={title} />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{description}</p>
        </div>
        <div className="card-footer bg-light">
          <small className="text-muted">Date: {date}</small>
          <div className="mt-2">
            <button
              className="btn btn-sm btn-outline-primary mr-2"
              onClick={() => onUpdate()}>
              Update
            </button>
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => onDelete()}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
