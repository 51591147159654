import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  adminGetSingleProduct,
  adminUpdateProduct,
} from "../../Service/admin-service";

export default function ProductUpdate() {
  const [formData, setFormData] = useState({});
  const [modifiedFields, setModifiedFields] = useState({});
  const [loading, setLoading] = useState(true);
  const { _id } = useParams();

  // Fetch the product data on component mount
  useEffect(() => {
    async function fetchProduct() {
      try {
        const response = await adminGetSingleProduct(_id);
        const data = response.data;
        setFormData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
        setLoading(false);
      }
    }

    fetchProduct();
  }, [_id]);

  // Handle changes in form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setModifiedFields({ ...modifiedFields, [name]: value });
  };

  // Handle form submission (product update)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await adminUpdateProduct(_id, modifiedFields);
      alert(response.message);
    } catch (error) {
      console.log(error);
      console.error("Error updating product:", error);
      // Handle error, show error message to the user, etc.
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Generate form fields based on product details
  const formFields = Object.entries(formData).map(([key, value]) => (
    <tr key={key}>
      <td>
        <label htmlFor={key}>{key.toUpperCase()}</label>
      </td>
      <td colSpan="2">
        <input
          type="text"
          className="form-control"
          id={key}
          name={key}
          value={modifiedFields[key] || value}
          onChange={handleChange}
        />
      </td>
    </tr>
  ));

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Update Product</h5>
        <form onSubmit={handleSubmit}>
          <table className="table">
            <tbody>{formFields}</tbody>
          </table>
          <button type="submit" className="btn btn-primary">
            Update
          </button>
        </form>
      </div>
    </div>
  );
}
