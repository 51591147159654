import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import AdminTitle from "../AdminTitle";
import { Link, useLocation } from "react-router-dom";
import {
  adminOwnBrand,
  deleteCategoryBrand,
} from "../../../Service/admin-service";
import { FaPlus } from "react-icons/fa";

export default function AdminHCB() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("q");

  const [data, setData] = useState([]);
  const [BrandCount, setBrandCount] = useState([]);

  const handleDelete = async (_id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this news?"
    );
    if (confirmDelete) {
      try {
        const response = await deleteCategoryBrand(_id);
        if (response.success === true) {
          alert(response.message);
          getData(); // Fetch fresh data after deletion
        }
      } catch (error) {
        console.log("Error in deleting data", error);
      }
    }
  };

  const getData = async () => {
    try {
      const response = await adminOwnBrand(category);

      if (response.success === true) {
        response.results.sort((a, b) =>
          (a.brand || "").localeCompare(b.brand || "")
        );
        setData(response.results);
        setBrandCount(response.totalBrand);
      }
    } catch (error) {
      console.log("error in admin category brand ", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          <AdminTitle
            title1={"Admin Brand"}
            title2={`Total Brand ${BrandCount}`}
          />

          <div className="row">
            <div className="col-lg-2 col-md-6 mb-4 m-3">
              <Link
                to={`/admin/home-category/add`}
                className="btn btn-primary mx-1">
                <FaPlus />
              </Link>
            </div>
            {data?.map((item, index) => (
              <div key={index} className="col-lg-2 col-md-6 mb-4 m-3">
                <Link>
                  <div className="card">
                    <img
                      src={item.imgUrl}
                      className="card-img-top"
                      alt="no img"
                    />
                    <div className="card-body">
                      <p className="card-text">{item.brand}</p>
                      <div className="d-flex justify-content-between align-items-center">
                        <Link
                          to={`/admin/home-category/update?_id=${item._id}`}
                          className="btn btn-primary btn-sm mr-2">
                          Update
                        </Link>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDelete(item._id)}>
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
