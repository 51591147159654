import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBlog } from "../../Service/blogService";
export default function Blog() {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getBlog();
      if (response.success === true) {
        setData(response.results);
      }
    } catch (error) {
      console.log("error in fetching data", error);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    fetchData();
  }, []);

  return (
    <>
      {data?.map((item, index) => (
        <div className="blog-card alt" key={index}>
          <div className="meta">
            <div
              className="photo"
              style={{
                backgroundImage: `url(${item?.img})`,
              }}
            />

            <ul className="details">
              <li className="author">
                <a href="#">By Admin</a>
              </li>
              <li className="date">{item?.date}</li>
              <li className="tags"></li>
            </ul>
          </div>
          <div className="description">
            <Link to={`/blog/${item?.title}`}>
              <h1>{item?.title}</h1>
            </Link>
            <p>{item?.description}</p>
            <p className="read-more">
              <Link to={`/blog/${item?.title}`}>Read More </Link>
            </p>
          </div>
        </div>
      ))}
    </>
  );
}
