import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getCategoryBrandById,
  updateCategoryBrand,
} from "../../../Service/admin-service";

export default function AdminUpdateHC() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const _id = query.get("_id");

  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [imgUrl, setimgUrl] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchCategoryBrand = async () => {
      try {
        const response = await getCategoryBrandById(_id);
        if (response.success === true) {
          const { category, brand, imgUrl } = response.result;
          setCategory(category);
          setBrand(brand);
          setimgUrl(imgUrl);
        } else {
          setError("Failed to fetch category brand data");
        }
      } catch (error) {
        setError("Error fetching category brand data");
      }
    };

    fetchCategoryBrand();
  }, [_id]);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleBrandChange = (e) => {
    setBrand(e.target.value);
  };

  const handleimgUrlChange = (e) => {
    setimgUrl(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!category || !brand || !imgUrl) {
      setError("Please fill out all fields");
      return;
    }
    const formData = {
      category,
      brand,
      imgUrl,
    };

    try {
      const response = await updateCategoryBrand(_id, formData);
      if (response.success === true) {
        alert(response.message);
        navigate(`/admin/home-category/brand?q=${formData.category}`);
      }
    } catch (error) {
      setError("Error updating category brand");
    }
  };

  return (
    <div className="container mt-5">
      <h2>Update Brand Image Category</h2>
      <form onSubmit={handleSubmit}>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="mb-3">
          <label htmlFor="category" className="form-label">
            Category Name:
          </label>
          <input
            type="text"
            className="form-control"
            id="category"
            value={category}
            onChange={handleCategoryChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="brand" className="form-label">
            Brand Name:
          </label>
          <input
            type="text"
            className="form-control"
            id="brand"
            value={brand}
            onChange={handleBrandChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="imgUrl" className="form-label">
            Category Image URL:
          </label>
          <input
            type="text"
            className="form-control"
            id="imgUrl"
            value={imgUrl}
            onChange={handleimgUrlChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Update Category
        </button>
      </form>
    </div>
  );
}
