import React from "react";
import Slider from "react-slick";
import SampleNextArrow from "../NewArrivals/SampleNextArrow";
import SamplePrevArrow from "../NewArrivals/SamplePrevArrow";
import { Link } from "react-router-dom";

const SlideContent = ({ children }) => {
  return <div className="slide-content">{children}</div>;
};

const MobileBrand = () => {
  const brands = [
    {
      name: "Lenovo",
      image: "https://mainprojectimg.s3.amazonaws.com/Lenovo_dxvgyb.webp",
    },
    {
      name: "GIONEE",
      image: "https://mainprojectimg.s3.amazonaws.com/blackberry.png",
    },
    {
      name: "Apple",
      image: "https://mainprojectimg.s3.amazonaws.com/apple_xmf55t.webp",
    },
    { name: "HTC", image: "https://mainprojectimg.s3.amazonaws.com/HTC.png" },
    {
      name: "LG",
      image: "https://mainprojectimg.s3.amazonaws.com/Lg_duygz8.webp",
    },
    { name: "Acer", image: "https://mainprojectimg.s3.amazonaws.com/acer.png" },
    {
      name: "Mi",
      image: "https://mainprojectimg.s3.amazonaws.com/mi_dtxgef.webp",
    },
    {
      name: "Alcatel",
      image: "https://mainprojectimg.s3.amazonaws.com/alcatel.png",
    },
    { name: "Asus", image: "https://mainprojectimg.s3.amazonaws.com/asus.png" },
    {
      name: "Samsung",
      image: "https://mainprojectimg.s3.amazonaws.com/samsung_tmmzob.webp",
    },
    {
      name: "OPPO",
      image: "https://mainprojectimg.s3.amazonaws.com/mi_dtxgef.webp",
    },
    {
      name: "Blackberry",
      image: "https://mainprojectimg.s3.amazonaws.com/blackberry.png",
    },
    {
      name: "Realme",
      image: "https://mainprojectimg.s3.amazonaws.com/realme_m8q5py.webp",
    },
    {
      name: "Google",
      image: "https://mainprojectimg.s3.amazonaws.com/google.png",
    },
    {
      name: "Honor",
      image: "https://mainprojectimg.s3.amazonaws.com/honor.png",
    },
    {
      name: "Huawei",
      image: "https://mainprojectimg.s3.amazonaws.com/huawei.png",
    },
    {
      name: "Infinix",
      image: "https://mainprojectimg.s3.amazonaws.com/infinix.png",
    },
    { name: "Lava", image: "https://mainprojectimg.s3.amazonaws.com/lava.png" },
    {
      name: "Nokia",
      image: "https://mainprojectimg.s3.amazonaws.com/nokia.png",
    },
    {
      name: "Oneplus",
      image: "https://mainprojectimg.s3.amazonaws.com/oneplus.png",
    },
    {
      name: "Vivo",
      image: "https://mainprojectimg.s3.amazonaws.com/vivo_sqsnbm.webp",
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    dots: false,
  };

  return (
    <div className="custom-slider p-5">
      <h3 className="Heading-text">Top Mobiles Brands</h3>
      <Slider {...settings}>
        {brands.map((brand, index) => (
          <Link to={`/MOBILES/${brand.name}`} key={brand.name}>
            <SlideContent>
              <img src={brand.image} alt={brand.name} />
              <p>{brand.name}</p>
            </SlideContent>
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default MobileBrand;
