import React, { useState } from "react";
import { motion } from "framer-motion";
import FooterListTitle from "./FooterListTitle";
import { Link } from "react-router-dom";
import { subscribeNewsletter } from "../../../Service/newsletterService";

const Footer = () => {
  const [emailInfo, setEmailInfo] = useState("");
  const [subscription, setSubscription] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const handleSubscription = async () => {
    if (emailInfo === "") {
      setErrMsg("Please provide an Email !");
    } else {
      try {
        // Call the subscribeNewsletter function from the service
        const response = await subscribeNewsletter(emailInfo);
        setErrMsg(""); // Clear any previous error message
        setSubscription(response.message);
        setEmailInfo(""); // Clear the email input field
        // Handle the response if needed
      } catch (error) {
        // Handle errors if the subscription fails
        console.error("Error subscribing to newsletter:", error);
        setErrMsg("Failed to subscribe to newsletter. Please try again later.");
      }
    }
  };
  return (
    <div className="w-full bg-[#F5F5F3] py-20">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2  xl:grid-cols-6 px-4 gap-10">
        <div className="col-span-2">
          <FooterListTitle title=" Gadgetfobia" />
          <div className="flex flex-col gap-6">
            <p className="text-base w-full xl:w-[80%]">
              Your Partner in Tech Exploration captures the essence of a trusted
              companion guiding you through the vast and ever-evolving landscape
              of technology. It promises a collaborative journey into
              discovering and leveraging the latest gadgets to enhance your
              digital lifestyle
            </p>
          </div>
        </div>
        <div>
          <FooterListTitle title="Shop" />
          <ul className="flex flex-col gap-2">
            <Link
              to="MOBILES/APPLE"
              className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Apple Smart Phone
            </Link>
            <Link
              to="SMART%20WATCH/SAMSUNG"
              className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Samsung Smart Watches
            </Link>
            <Link
              to="PRINTER/BROTHER"
              className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Brother Printer
            </Link>
            <Link
              to="/SMART%20TV/LG"
              className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              LG Smart Tv
            </Link>
          </ul>
        </div>
        <div>
          <FooterListTitle title="Your account" />
          <ul className="flex flex-col gap-2">
            <Link
              to="/profile"
              className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Profile
            </Link>
            <Link
              to="/favourite"
              className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Favourite
            </Link>
            <Link
              to="/News"
              className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              News
            </Link>
            <Link
              to="/blog"
              className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Blog
            </Link>
            <Link
              to="/about"
              className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              About Us
            </Link>
          </ul>
        </div>
        <div className="col-span-2 flex flex-col items-center w-full px-4">
          <FooterListTitle title="Subscribe to our newsletter." />
          <div className="w-full">
            {subscription ? (
              <motion.p
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="w-full text-center text-base font-titleFont font-semibold text-green-600">
                {subscription}
              </motion.p>
            ) : (
              <div className="w-full flex-col xl:flex-row flex justify-between items-center gap-4">
                <div className="flex flex-col w-full">
                  <input
                    onChange={(e) => setEmailInfo(e.target.value)}
                    value={emailInfo}
                    className="w-full h-12 border-b border-gray-400 bg-transparent px-4 text-primeColor text-lg placeholder:text-base outline-none"
                    type="text"
                    placeholder="Insert your email ...*"
                  />
                  {errMsg && (
                    <p className="text-red-600 text-sm font-semibold font-titleFont text-center animate-bounce mt-2">
                      {errMsg}
                    </p>
                  )}
                </div>
                <button
                  onClick={handleSubscription}
                  className="bg-white text-lightText w-[40%] duration-300 text-base tracking-wide p-3"
                  style={{ borderRadius: "10px" }}>
                  <b> Subscribe</b>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
