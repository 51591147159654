import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Sidebar.module.css"; // Import your CSS module here
import { adminLogout } from "../../Service/adminService";

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const adminaccessToken = localStorage.getItem("adminaccessToken");

      if (!adminaccessToken) {
        console.log("Admin access token not found in local storage");
        return;
      }

      const response = await adminLogout(adminaccessToken);
      if (response.statusCode === 200) {
        localStorage.removeItem("adminaccessToken");
        localStorage.removeItem("adminrefreshToken");
        alert(response.message);

        navigate("/admin/auth");
      }
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebar}>
        <ul className={styles.sidebarMenu}>
          <li>
            <Link to="/admin">Home</Link>
          </li>

          <li>
            <Link to="/admin/home-category">Home Category</Link>
          </li>
          <li>
            <Link to="/admin/product-category">Product Category</Link>
          </li>
          {/* <li>
            <Link to="/admin/news">News</Link>
          </li> */}

          <li>
            <Link to="/admin/profile">Profile</Link>
          </li>

          <li>
            <button onClick={handleLogout}>Logout</button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
