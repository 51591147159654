import React, { useEffect, useState } from "react";
import { ReadNavNews } from "../../../Service/newsService";
import { Link } from "react-router-dom";

export default function NewsPage() {
  const [data, setData] = useState([]);
  const [newsDate, setNewsDate] = useState("");

  const fetchData = async () => {
    try {
      const response = await ReadNavNews(newsDate);
      if (response.success === true) {
        setData(response.results);
      }
    } catch (error) {
      console.log("Error in fetching data", error);
    }
  };

  const handleDateChange = (event) => {
    setNewsDate(event.target.value);
  };

  const handleSearchForNews = async (event) => {
    event.preventDefault();
    fetchData();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  return (
    <div>
      <div className="wrap">
        <div className="d-flex align-center justify-content-between">
          <h1 className="Heading-text">
            <u> News You May Like</u>
            <i className="bi bi-arrow-right"></i>
          </h1>
          <div className="gf-datepicker">
            <form onSubmit={handleSearchForNews}>
              <label htmlFor="newsdate">Read News Of:</label>
              <input
                type="date"
                id="newsdate"
                name="newsdate"
                value={newsDate}
                onChange={handleDateChange}
                required
              />
              <button className="btn btn-sm btn-primary">Search</button>
            </form>
          </div>
        </div>
        {data && data.length > 0 ? (
          data.map((newsItem) => (
            <div key={newsItem._id} className="post">
              <div className="feat-img">
                <img src={newsItem.img} alt={newsItem.title} />
              </div>
              <article>
                <header>
                  <ul className="cat">
                    <li>
                      <a href="#">Articles</a>
                    </li>
                    <li>
                      <a href="#">News</a>
                    </li>
                    <li>
                      <a href="#">Breaking</a>
                    </li>
                  </ul>
                  <h2>
                    <Link to={`/News/${newsItem.title}`}>{newsItem.title}</Link>
                  </h2>
                  <p className="author-cred">
                    Published on {newsItem.createdAt}
                  </p>
                </header>
                <p>
                  {newsItem.description.slice(0, 300)}...{" "}
                  <Link to={`/News/${newsItem.title}`}>Read More</Link>
                </p>
              </article>
            </div>
          ))
        ) : (
          <p>No news available for the specified date.</p>
        )}
      </div>
      {/* end wrap */}
    </div>
  );
}
