import api from "../Service/apiHelper";

export const ReadHomeNews = async () => {
  try {
    const response = await api.get(`api/v1/news/read-home-news`);
    return response.data;
  } catch (error) {
    throw error.response.data.message || "An error occurred";
  }
};

export const ReadSingleNews = async (_id) => {
  try {
    const response = await api.get(`api/v1/news/read-news/${_id}`);
    return response.data;
  } catch (error) {
    throw error.response.data.message || "An error occurred";
  }
};

export const ReadNavNews = async (newsDate) => {
  try {
    const response = await api.get(`api/v1/news/read-nav-news?q=${newsDate}`);
    return response.data;
  } catch (error) {
    throw error.response.data.message || "An error occurred";
  }
};
