import api from "../Service/apiHelper";
// ###############################################################################
export const getCategoryData = async () => {
  try {
    const response = await api.post(`api/v1/product/productlisting`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// ###############################################################################
export const getProduct_ppData = async () => {
  try {
    const response = await api.post(`api/v1/product/popularproduct`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// ###############################################################################
export const getBrandData = async (category) => {
  try {
    const response = await api.post(`api/v1/product/brands/${category}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// ###############################################################################
export const getProductBriefData = async (category, brand) => {
  try {
    const response = await api.post(`api/getproductbrief/${category}/${brand}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// ###############################################################################
export const getSingleProductData = async (category, brand, _id) => {
  try {
    const response = await api.post(
      `api/v1/product/productdetail/${category}/${brand}/${_id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
// ###############################################################################
export const Filterdata = async (category, brand, selectedCategories) => {
  try {
    const selectedOptionsArray = Object.entries(selectedCategories).map(
      ([subCategory, selectedOption]) =>
        `${subCategory}=${encodeURIComponent(selectedOption)}`
    );
    const response = await api.post(
      `api/v1/feature/filterproduct?category=${category}&brand=${brand}&${selectedOptionsArray.join(
        "&"
      )}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching data. Please try again later.");
  }
};
// ###############################################################################

export const TopMobiles = async (modelName, brand, _id) => {
  try {
    const response = await api.post(
      `api/v1/product/productdetail/${modelName}/${brand}/${_id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
