import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import SmallCard from "./SmallCard";
import AdminTitle from "./AdminTitle";
import { adminProductCategory } from "../../Service/admin-service";
import { Link } from "react-router-dom";

export default function AdminProductCategory() {
  const [data, setData] = useState([]);
  const [totalCategory, settotalCategory] = useState(0);

  const getData = async () => {
    try {
      let response = await adminProductCategory();
      if (response.success === true) {
        setData(response.results);
        settotalCategory(response.totalCategory);
      }
    } catch (error) {
      console.log("error in admin product category count", error);
    }
  };

  const handleAddCategory = (newCategory) => {
    console.log(newCategory);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          <AdminTitle
            title1={"Admin Category"}
            title2={`Total Category ${totalCategory}`}
          />

          <div className="row" onClick={() => handleAddCategory}>
            <div className="col-lg-2 col-md-6 mb-4 m-3">
              <SmallCard title={"Add New Category"} onAdd={handleAddCategory} />
            </div>
            {data?.map((item, index) => (
              <div key={index} className="col-lg-2 col-md-6 mb-4 m-3">
                <Link to={`/admin/${item.category}`}>
                  <SmallCard
                    title={<b>{item.category}</b>}
                    desc={
                      <p>
                        Brands: <b>{item.brandCount}</b>
                      </p>
                    }
                    content={
                      <p>
                        Products: <b>{item.productCount}</b>
                      </p>
                    }
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
