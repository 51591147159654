import React, { Component } from "react";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedIndex: null,
    };
  }

  handleToggle = (index) => {
    this.setState((prevState) => ({
      expandedIndex: prevState.expandedIndex === index ? null : index,
    }));
  };

  render() {
    const { category, faqs } = this.props;
    const { expandedIndex } = this.state;

    if (!faqs || faqs.length === 0) {
      return (
        <div className="faq-container">
          No FAQs available for this category.
        </div>
      );
    }

    const formattedCategory = category.toUpperCase().replace("_", "");

    const filteredFaqs = faqs.filter(
      (faq) => faq.category.toUpperCase().replace("_", "") === formattedCategory
    );

    if (filteredFaqs.length === 0) {
      return (
        <div className="faq-container p-5">
          No FAQs available for this category.
        </div>
      );
    }

    return (
      <div className="faq-container p-5">
        <h2 className="faq-category Heading-text">{formattedCategory} FAQs</h2>
        {filteredFaqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <p
              className="faq-question"
              onClick={() => this.handleToggle(index)}
            >
              <strong>{faq.question}</strong>
              {expandedIndex === index ? " ▲" : " ▼"}
            </p>
            {expandedIndex === index && (
              <p className="faq-answer">{faq.answer}</p>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default FAQ;
