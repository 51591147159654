import React from "react";
import FilterKey from "./ShopBy/FilterKey";
import BrandFilter from "./ShopBy/BrandFilter";

const ShopSideNav = ({ category, brand, topbrand }) => {
  return (
    <div className="w-full flex flex-col gap-6">
      <FilterKey category={category} brand={brand} />
      <BrandFilter Brand={topbrand} />
    </div>
  );
};

export default ShopSideNav;
