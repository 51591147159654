import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { currentadmin } from "../../Service/adminService";
import { useAuth } from "../../Context/AuthContext";
const usePrivateAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const { getToken } = useAuth();
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = getToken("adminaccessToken");
        const response = await currentadmin(token);
        if (response.statusCode === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        setLoading(false);
      } catch (error) {
        console.log(error.response);
        setIsAuthenticated(false);
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  return { isAuthenticated, loading };
};

const PrivateRoute = () => {
  const { isAuthenticated, loading } = usePrivateAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/admin/auth" />;
};

export default PrivateRoute;
