import api from "../Service/apiHelper";

export const searchSuggestion = async (searchQuery) => {
  try {
    const response = await api.post(
      `api/v1/feature/v11Suggestion?query=${searchQuery}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw new Error("An error occurred while fetching search results.");
  }
};

export const DynamicSearch = async (searchQuery) => {
  try {
    const response = await api.post(
      `api/v1/feature/vv1Search?query=${searchQuery}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching search results:", error);
  }
};

export const searchForComparison = async (modelName, category) => {
  try {
    const response = await api.post(`api/v1/feature/comparetest`, {
      modelName,
      category,
    });

    return response.data;
  } catch (error) {
    console.error("Error searching for comparison:", error.message);
    throw new Error("An error occurred while searching for comparison.");
  }
};
