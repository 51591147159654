import React, { useContext, useEffect, useState } from "react";
import { distinctCategories } from "../../../../Service/featureService";
import { TopBrandContext } from "../../../../Context/TopBrandContext";
const BrandFilter = ({ Brand }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { fetchData } = useContext(TopBrandContext);
  const fetchApiData = async () => {
    try {
      const response = await distinctCategories(Brand);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchApiData();
  }, [Brand]);

  const handleCheckboxChange = (category) => {
    setSelectedCategory(selectedCategory === category ? "" : category);
    sendSelectedCategoryToBackend(
      selectedCategory === category ? "" : category
    );
  };

  const sendSelectedCategoryToBackend = async (value) => {
    await fetchData({ brand: Brand, category: value });
  };

  return (
    <div className="brand-filter-container">
      <div className="sidebar">
        <ul>
          {categories.map((category, index) => (
            <li key={index}>
              <label>
                <input
                  type="checkbox"
                  value={category}
                  checked={selectedCategory === category}
                  onChange={() => handleCheckboxChange(category)}
                />
                {category}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BrandFilter;
