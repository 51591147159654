import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { FaSearch, FaUser, FaCaretDown } from "react-icons/fa";
import Flex from "../../../Components/DesignLayouts/Flex";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { BsSuitHeartFill } from "react-icons/bs";
import { logoutUser } from "../../../Service/userService";
import { searchSuggestion } from "../../../Service/featureService";

const HeaderBottem = () => {
  const categoriesWithBrands = [
    { category: "MOBILES" },
    { category: "SMART WATCH" },
    { category: "ROUTER" },
    { category: "PRINTER" },
    { category: "EARPHONES" },
    { category: "TABLETS" },
    { category: "EXTENDER" },
    { category: "GRAPHIC CARD" },
    { category: "MOTHERBOARD" },
    { category: "LAPTOPS" },
    { category: "PROCESSORS" },
    { category: "COMPUTERS" },
    { category: "MOUSES" },
    { category: "SMART GLASS" },
    { category: "GAMING CONSOLES" },
    { category: "SPEAKERS" },
    { category: "VR HEADSETS" },
    { category: "KEYBOARD" },
    { category: "FIREWALLS" },
    { category: "SMART TV" },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false);
  const [result, SetResult] = useState([]);
  const navigate = useNavigate();
  const [showUser, setShowUser] = useState(false);
  const [hoveredCategories, setHoveredCategories] = useState({});

  const handleCategoryHover = (category) => {
    setHoveredCategories((prev) => ({ ...prev, [category]: true }));
  };

  const handleCategoryLeave = (category) => {
    setHoveredCategories((prev) => ({ ...prev, [category]: false }));
  };

  const ref = useRef();
  const categoryRef = useRef();
  const searchResultRef = useRef();
  const userDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (categoryRef.current && !categoryRef.current.contains(e.target)) {
        setShow(false);
      }
      if (
        searchResultRef.current &&
        !searchResultRef.current.contains(e.target)
      ) {
        setSearchQuery("");
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideUserDropdown = (e) => {
      if (
        userDropdownRef.current &&
        !userDropdownRef.current.contains(e.target)
      ) {
        setShowUser(false);
      }
    };

    if (showUser) {
      document.addEventListener("click", handleClickOutsideUserDropdown);
    }

    return () => {
      document.removeEventListener("click", handleClickOutsideUserDropdown);
    };
  }, [showUser]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (ref.current && ref.current.contains(e.target)) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [show]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchData = async () => {
    if (searchQuery.trim() !== "") {
      let response = await searchSuggestion(searchQuery);
      SetResult(response.results);
    }
  };

  const navigateto = (item) => {
    navigate(`/searchresult?query=${item}`);
    setSearchQuery("");
  };

  useEffect(() => {
    fetchData();
  }, [searchQuery]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchQuery.trim() !== "") {
      navigate(`/searchresult?query=${searchQuery}`);
      setSearchQuery("");
    }
  };

  const isLoggedIn = !!localStorage.getItem("accessToken");

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await logoutUser(token);
      if (response.success === true) {
        toast.warn(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("accessToken");
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  return (
    <div className="w-full bg-[#F5F5F3] relative">
      <div className="max-w-container mx-auto">
        <Flex className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24">
          <div
            onClick={() => setShow(!show)}
            ref={ref}
            className="flex h-14 cursor-pointer items-center gap-2 text-primeColor category-button"
          >
            <p className="text-[14px] font-normal">
              <i className="bi bi-list"></i> Category
            </p>

            {show && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-36 z-50 bg-primeColor w-auto text-[#767676] h-auto p-4 pb-6"
              >
                {categoriesWithBrands.map((categoryItem, index) => (
                  <li
                    key={index} // Using index as a fallback key
                    onMouseEnter={() =>
                      handleCategoryHover(categoryItem.category)
                    }
                    onMouseLeave={() =>
                      handleCategoryLeave(categoryItem.category)
                    }
                  >
                    <Link to={`/${categoryItem.category}`}>
                      <div>
                        <p className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                          {categoryItem.category}
                        </p>
                        {hoveredCategories[categoryItem.category] && (
                          <div className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer brands-sidbar"></div>
                        )}
                      </div>
                    </Link>
                  </li>
                ))}
              </motion.ul>
            )}
          </div>

          <div className="ser mt-4 relative w-full lg:w-[600px] md:w-[500px] sm:w-[260px] h-[50px] text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl">
            <input
              className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]"
              type="text"
              onChange={handleSearch}
              onKeyPress={handleKeyPress}
              value={searchQuery}
              placeholder="Search your products here"
            />
            <FaSearch className="w-5 h-5 " />
            {searchQuery && (
              <div
                ref={searchResultRef}
                className="w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer"
              >
                {searchQuery &&
                  result.map((item, index) => (
                    <div
                      key={index} // Using index as a fallback key
                      onClick={() => navigateto(item.suggestion)}
                      className="max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-3"
                    >
                      <div className="w-100 flex-col gap-1 d-flex justify-center">
                        <p className="font-semibold text-lg d-flex justify-center">
                          {item.suggestion}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

          <div className="profile-res flex gap-2 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative">
            <div
              onClick={() => setShowUser(!showUser)}
              className="flex"
              ref={userDropdownRef}
            >
              <FaUser />
              <FaCaretDown />
            </div>
            {showUser && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="profile-box absolute top-6 left-0 z-50 bg-primeColor w-44 text-[#767676] h-auto p-4 pb-6"
              >
                {isLoggedIn ? (
                  <li
                    className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer"
                    onClick={handleLogout}
                  >
                    Logout
                  </li>
                ) : (
                  <>
                    <Link to="/login">
                      <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                        Login
                      </li>
                    </Link>
                    <Link onClick={() => setShowUser(false)} to="/signup">
                      <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                        Sign Up
                      </li>
                    </Link>
                  </>
                )}

                <Link
                  to={"/profile"}
                  className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer"
                >
                  Profile
                </Link>
              </motion.ul>
            )}

            <Link to={"/favourite"}>
              <BsSuitHeartFill />
            </Link>
          </div>
        </Flex>
      </div>
      <ToastContainer />
    </div>
  );
};

export default HeaderBottem;
