import React, { useEffect, useState } from "react";
import { getSingleProductData } from "../../../Service/Api"; // Adjust the import path to where your API function is located

export default function Pros(props) {
  const [selectedItem, setSelectedItem] = useState({ pros: [], cons: [] });
  const [loading, setLoading] = useState(true); // State to manage loading state

  useEffect(() => {
    const fetchData = async () => {
      // Extracting props
      const { category, brand, _id } = props;

      try {
        const response = await getSingleProductData(category, brand, _id);
        if (response && response.status === "success") {
          setSelectedItem(response.data);
        } else {
          console.error(
            "API call was successful but did not return expected data."
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or error
      }
    };
    fetchData();
  }, [props]); // Now useEffect will run whenever props change

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item list-group-item-success">Pros</li>
              {loading ? (
                <li className="list-group-item border">Loading...</li>
              ) : selectedItem.pros && selectedItem.pros.length > 0 ? (
                selectedItem.pros.map((pro, index) => (
                  <li key={`pro-${index}`} className="list-group-item border">
                    {pro}
                  </li>
                ))
              ) : (
                <li className="list-group-item border">No pros available</li>
              )}
            </ul>
          </div>
          <div className="col-md-6 col-sm-12">
            <ul className="list-group">
              <li className="list-group-item list-group-item-danger">Cons</li>
              {loading ? (
                <li className="list-group-item border">Loading...</li>
              ) : selectedItem.cons && selectedItem.cons.length > 0 ? (
                selectedItem.cons.map((con, index) => (
                  <li key={`con-${index}`} className="list-group-item border">
                    {con}
                  </li>
                ))
              ) : (
                <li className="list-group-item border">No cons available</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
