import React, { useEffect, useState, useContext } from "react";
import { filterkey } from "../../../../Service/featureService";
import { ProductContext } from "../../../../Context/ProductContext";

const FilterKey = ({ category, brand }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { updateProducts } = useContext(ProductContext);
  const [selectedFilters, setSelectedFilters] = useState({});

  const fetchData = async () => {
    try {
      const response = await filterkey(category);
      if (response && response.options && Array.isArray(response.options)) {
        setData(response.options);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [category, brand]);

  const handleCheckboxChange = async (name, value) => {
    setLoading(true);
    try {
      const updatedFilters = {
        ...(selectedFilters || {}),
        [name]: selectedFilters[name] === value ? null : value, // Toggle filter value
      };
      await updateProducts({ category, brand, ...updatedFilters });
      setSelectedFilters(updatedFilters);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="sidebar">
      <div className="filter-container">
        {data.length > 0 ? (
          data.map((item, index) => (
            <div key={index} className="filter-group">
              <h3>{item.label}:</h3>
              <ul>
                {item.values.map((value, valIndex) => (
                  <Checkbox
                    key={valIndex}
                    name={item.name}
                    value={value.value}
                    isChecked={selectedFilters[item.name] === value.value}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                ))}
              </ul>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

const Checkbox = ({ name, value, isChecked, handleCheckboxChange }) => {
  return (
    <li>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => handleCheckboxChange(name, value)}
        />
        <span>{value}</span>
      </label>
    </li>
  );
};

export default FilterKey;
