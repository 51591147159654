import React from "react";
import styles from "./AdminTitle.module.css"; // Import your CSS module here

export default function AdminTitle({ title1, title2 }) {
  return (
    <div className={styles.titleStrip}>
      <h1>{title1}</h1>
      <h2>{title2}</h2>
    </div>
  );
}
