import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./adminaddproduct.css";
import { addNewProduct } from "../../../src/Service/admin-service";
import toast from "react-hot-toast";
export default function AdminAddProduct() {
  const { category, brand } = useParams();

  // Form State
  const [modelName, setModelName] = useState("");
  const [releaseDate, setReleaseDate] = useState("");
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({});
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [pros, setPros] = useState([]);
  const [cons, setCons] = useState([]);
  const [newPro, setNewPro] = useState("");
  const [newCon, setNewCon] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle adding and deleting extra fields
  const handleAddField = () => {
    if (newKey.trim() !== "" && newValue.trim() !== "") {
      setFormData({ ...formData, [newKey]: newValue });
      setNewKey("");
      setNewValue("");
    }
  };

  const handleDeleteField = (key) => {
    const newFormData = { ...formData };
    delete newFormData[key];
    setFormData(newFormData);
  };

  // Handle image upload
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  // Handle adding and deleting pros and cons
  const handleAddPro = () => {
    if (newPro.trim() !== "") {
      setPros([...pros, newPro.trim()]);
      setNewPro("");
    }
  };

  const handleDeletePro = (index) => {
    setPros(pros.filter((_, i) => i !== index));
  };

  const handleAddCon = () => {
    if (newCon.trim() !== "") {
      setCons([...cons, newCon.trim()]);
      setNewCon("");
    }
  };

  const handleDeleteCon = (index) => {
    setCons(cons.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    // Validate required fields
    if (
      !image ||
      modelName.trim() === "" ||
      releaseDate.trim() === "" ||
      pros.length === 0 ||
      cons.length === 0 ||
      Object.keys(formData).length === 0 // Check if formData is empty
    ) {
      toast.error("Please fill in all required fields before submitting.");
      setLoading(false); // Reset loading state on validation failure
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("img", image);
    formDataToSend.append("category", category);
    formDataToSend.append("brand", brand);
    formDataToSend.append("release_date", releaseDate);
    formDataToSend.append("model_name", modelName);
    pros.forEach((pro) => formDataToSend.append("pros", pro));
    cons.forEach((con) => formDataToSend.append("cons", con));

    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await addNewProduct(formDataToSend);
      if (response.success === true) {
        toast.success(response.message); // Display success message
        resetForm(); // Reset form fields after successful submission
      } else {
        toast.error("Failed to add product. Please try again."); // Generic error message for failure
      }
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Something went wrong while adding the product."); // Network or unexpected error handling
    } finally {
      setLoading(false); // Reset loading state in finally block to ensure it's reset in all scenarios
    }
  };

  // Reset form state after successful submission
  const resetForm = () => {
    setModelName("");
    setReleaseDate("");
    setImage(null);
    setFormData({});
    setNewKey("");
    setNewValue("");
    setPros([]);
    setCons([]);
    setNewPro("");
    setNewCon("");
    const fileInput = document.getElementById("imgforadminaddproduct");
    fileInput.value = "";
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-header bg-info text-white text-center">
              Add Product Page
            </div>
            <div className="adminaddproduct_card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="Category" className="form-label">
                        Category <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={category}
                        disabled={true}
                        className="form-control"
                        placeholder="Enter Category"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="Brand" className="form-label">
                        Brand <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={brand}
                        disabled={true}
                        className="form-control"
                        placeholder="Enter Brand"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="Model Name" className="form-label">
                        Model Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        value={modelName}
                        onChange={(e) => setModelName(e.target.value)}
                        className="form-control mt-2"
                        placeholder="Enter Model Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="releaseDate" className="form-label">
                        Release Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        value={releaseDate}
                        onChange={(e) => setReleaseDate(e.target.value)}
                        className="form-control mt-2"
                        placeholder="Enter Release Date"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="Image" className="form-label">
                        Image <span className="text-danger">*</span>
                      </label>
                      <input
                        id="imgforadminaddproduct"
                        type="file"
                        onChange={handleImageChange}
                        className="form-control"
                        placeholder="Upload Product Image"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="table table-bordered mt-3">
                      <thead>
                        <tr>
                          <th>Key Name</th>
                          <th>Value</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(formData).map(([key, value], index) => (
                          <tr key={index}>
                            <td>{key}</td>
                            <td>{value}</td>
                            <td>
                              <button
                                type="button"
                                onClick={() => handleDeleteField(key)}
                                className="btn btn-sm btn-danger">
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td>
                            <input
                              type="text"
                              value={newKey}
                              onChange={(e) => setNewKey(e.target.value)}
                              className="form-control"
                              placeholder="Enter Key Name"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={newValue}
                              onChange={(e) => setNewValue(e.target.value)}
                              className="form-control"
                              placeholder="Enter Value"
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              onClick={handleAddField}
                              className="btn btn-sm adminaddproduct_btn_addproduct">
                              Add This Specification
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <table className="table table-bordered mt-3">
                      <thead>
                        <tr>
                          <th>Pros</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pros.map((pro, index) => (
                          <tr key={index}>
                            <td>{pro}</td>
                            <td>
                              <button
                                type="button"
                                onClick={() => handleDeletePro(index)}
                                className="btn btn-sm adminaddproduct_btn_delete">
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td>
                            <input
                              type="text"
                              value={newPro}
                              onChange={(e) => setNewPro(e.target.value)}
                              className="form-control"
                              placeholder="Enter Pro"
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              onClick={handleAddPro}
                              className="btn btn-sm adminaddproduct_btn">
                              Add Pro
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-6">
                    <table className="table table-bordered mt-3">
                      <thead>
                        <tr>
                          <th>Cons</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cons.map((con, index) => (
                          <tr key={index}>
                            <td>{con}</td>
                            <td>
                              <button
                                type="button"
                                onClick={() => handleDeleteCon(index)}
                                className="btn btn-sm adminaddproduct_btn_delete">
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td>
                            <input
                              type="text"
                              value={newCon}
                              onChange={(e) => setNewCon(e.target.value)}
                              className="form-control"
                              placeholder="Enter Con"
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              onClick={handleAddCon}
                              className="btn btn-sm adminaddproduct_btn">
                              Add Con
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <button
                  onClick={handleSubmit}
                  className="btn adminaddproduct_btn_addproduct_main mt-3 w-100"
                  disabled={loading}>
                  {loading ? "Adding Product..." : "Add Product"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}
