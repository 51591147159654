import React, { useState } from "react";
import { addcategoryBrandImgUrl } from "../../../Service/admin-service";
import { useNavigate } from "react-router-dom";
export default function AdminAddHC() {
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [imgUrl, setimgUrl] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleBrandChange = (e) => {
    setBrand(e.target.value);
  };

  const handleimgUrlChange = (e) => {
    setimgUrl(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!category || !brand || !imgUrl) {
      setError("Please fill out all fields");
      return;
    }
    const formData = {
      category,
      brand,
      imgUrl,
    };

    try {
      const response = await addcategoryBrandImgUrl(formData);
      if (response.success === true) {
        alert(response.message);
        navigate(`/admin/home-category`);
      }
    } catch (error) {
      console.log("error in submiting form");
    }

    setCategory("");
    setBrand("");
    setimgUrl("");
    setError("");
  };

  return (
    <div className="container mt-5">
      <h2>Add Brand Image Category</h2>
      <form onSubmit={handleSubmit}>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="mb-3">
          <label htmlFor="category" className="form-label">
            Category Name:
          </label>
          <input
            type="text"
            className="form-control"
            id="category"
            value={category}
            onChange={handleCategoryChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="brand" className="form-label">
            Brand Name:
          </label>
          <input
            type="text"
            className="form-control"
            id="brand"
            value={brand}
            onChange={handleBrandChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="imgUrl" className="form-label">
            Category Image URL:
          </label>
          <input
            type="text"
            className="form-control"
            id="imgUrl"
            value={imgUrl}
            onChange={handleimgUrlChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Add Category
        </button>
      </form>
    </div>
  );
}
