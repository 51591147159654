import React from "react";
import Sidebar from "./Sidebar";

export default function Newsletter() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-9">
          <h2 className="mb-4">Sample Cards</h2>
        </div>
      </div>
    </div>
  );
}
