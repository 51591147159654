import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import { getBlogByKeyword } from "../../Service/blogService";

export default function BlogPage() {
  const { title } = useParams();
  const [data, setData] = useState({});
  const [EditerContent, setEditerContent] = useState("");
  const [metaTag, setmetaTag] = useState("");

  const fetchData = async () => {
    try {
      const response = await getBlogByKeyword(title);
      if (response.success === true) {
        setData(response.result);
        setEditerContent(response.result.content);
        setmetaTag(response.result.meta);
      }
    } catch (error) {
      console.log("error in fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
    window.scroll(0, 0);
  }, [title]);

  useEffect(() => {
    const adjustIframeHeight = () => {
      const iframe = document.getElementById("content-iframe");
      if (iframe && iframe.contentWindow) {
        iframe.style.height = "auto"; // Reset height to auto to recalculate
        const doc = iframe.contentWindow.document;
        const newHeight = doc.documentElement.scrollHeight;
        iframe.style.height = `${newHeight}px`;
      }
    };

    if (EditerContent) {
      const iframe = document.getElementById("content-iframe");
      if (iframe) {
        iframe.onload = adjustIframeHeight;
        // Adjust height again in case content is loaded after onload event
        setTimeout(adjustIframeHeight, 500);
      }
    }

    window.addEventListener("resize", adjustIframeHeight);

    return () => {
      window.removeEventListener("resize", adjustIframeHeight);
    };
  }, [EditerContent]);

  useEffect(() => {
    const adjustIframeHeight = () => {
      const iframe = document.getElementById("content-iframe");
      if (iframe && iframe.contentWindow) {
        iframe.style.height = "auto"; // Reset height to auto to recalculate
        const doc = iframe.contentWindow.document;
        const newHeight = doc.documentElement.scrollHeight;
        iframe.style.height = `${newHeight}px`;
      }
    };

    if (EditerContent) {
      const iframe = document.getElementById("content-iframe");
      if (iframe) {
        iframe.onload = adjustIframeHeight;
        // Adjust height again in case content is loaded after onload event
        setTimeout(adjustIframeHeight, 500);
      }
    }

    window.addEventListener("resize", adjustIframeHeight);

    return () => {
      window.removeEventListener("resize", adjustIframeHeight);
    };
  }, [EditerContent]);

  return (
    <>
      <Helmet>
        <title>{metaTag?.title}</title>
        <meta name="description" content={metaTag?.description} />
        {metaTag?.keywords && (
          <meta name="keywords" content={metaTag.keywords.join(", ")} />
        )}
      </Helmet>
      <div className="col-md-10 mx-auto p-4">
        <h1 style={{ fontWeight: 600, fontSize: "2rem" }}>{data?.title}</h1>
        <div className="py-3 text-dark flex items-center justify-center">
          <small className="mr-3 flex flex-row items-center">
            <svg
              fill="currentColor"
              height="13px"
              width="13px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style={{ enableBackground: "new 0 0 512 512" }}
              xmlSpace="preserve">
              <g>
                <g>
                  <path
                    d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256
                      c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128
                      c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z"
                  />
                </g>
              </g>
            </svg>
            <span className="ml-1">{data?.date}</span>
          </small>
          <small>
            <a href="#" className="flex flex-row items-center text-dark mr-3">
              <svg
                className="text-indigo-600"
                fill="currentColor"
                height="16px"
                aria-hidden="true"
                role="img"
                focusable="false"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="currentColor"
                  d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
              <span className="ml-1">By Admin</span>
            </a>
          </small>
        </div>
      </div>
      <div
        className="col-md-10 mx-auto"
        style={{
          height: 400,
          backgroundImage: `url(${data?.img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div>
        <iframe
          id="content-iframe"
          title="Jodit Content"
          frameBorder="0"
          style={{
            width: "100%",
            height: "auto", // Adjust height dynamically
            overflow: "hidden", // Hide scrollbar
          }}
          srcDoc={
            EditerContent
              ? DOMPurify.sanitize(`
                <style>
                  body { overflow: hidden; margin: 0; padding: 0; }
                  html { overflow: hidden; }
                </style>
                ${EditerContent}
              `)
              : ""
          }
          onLoad={() => {
            const iframe = document.getElementById("content-iframe");
            if (iframe) {
              setTimeout(() => {
                iframe.style.height =
                  iframe.contentWindow.document.documentElement.scrollHeight +
                  "px";
              }, 500); // Delay to ensure content is fully rendered
            }
          }}
        />
      </div>
    </>
  );
}
