import React from "react";

const UsageTips = ({ tips }) => {
  return (
    <div className="usage-tips-container p-5 border">
      <h2 className="usage-tips-heading Heading-text ">Usage Tips and Tricks</h2>
      {tips.map((tip, index) => (
        <div className="tip-item p-3 border mt-3" key={index}>
          <h3 className="tip-title">{tip.title}</h3>
          <p className="tip-description">{tip.description}</p>
        </div>
      ))}
    </div>
  );
};

export default UsageTips;
