import React from "react";
import NewArrivals from "../Home/NewArrivals/NewArrival";

export default function About() {
  return (
    <div>
      <div className="gf-about-sec my-lg-5 my-4 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-3 mb-lg-0">
              <img
                src={"https://mainprojectimg.s3.amazonaws.com/about-img.png"}
                alt="About"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-7">
              <div className="mb-4">
                <h2 className="fs-1 fw-bold">About Us</h2>
              </div>
              <p className="lh-base mb-3">
                At Gadgetfobia, we are passionate about gadgets and technology.
                Our mission is to provide you with comprehensive, accurate, and
                up-to-date information on every type of gadget you can imagine.
                From smartphones and tablets to smart home devices and
                wearables, we cover it all.
              </p>
              <div className="mb-4">
                <h3 className="fs-5 mb-2 fw-bold">Who We Are</h3>
              </div>

              <p className="lh-base mb-3">
                Gadgetfobia is your ultimate resource for gadget specifications,
                pros and cons, and practical tips and tricks. Our team of tech
                enthusiasts and experts is dedicated to helping you make
                informed decisions about the latest gadgets on the market. We
                believe that technology should enhance your life, not complicate
                it, and our goal is to simplify the tech world for you.
              </p>
              <div className="mb-4">
                <h3 className="fs-5 mb-2 fw-bold">What We Offer</h3>
              </div>
              <ul>
                <li>
                  <b> In-Depth Reviews:</b> Our detailed reviews provide a
                  thorough analysis of each gadget's features, performance, and
                  value.
                </li>
                <li>
                  <b> Pros and Cons:</b>We give you a balanced view by listing
                  the advantages and disadvantages of each gadget, helping you
                  weigh your options.
                </li>
                <li>
                  <b>Tips and Tricks:</b>Discover useful tips and tricks to get
                  the most out of your gadgets and optimize your user
                  experience.
                </li>
                <li>
                  <b> Comprehensive Specifications:</b>Find complete and
                  accurate specifications for a wide range of gadgets, all in
                  one place.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4">
        <NewArrivals />
      </div>
    </div>
  );
}
