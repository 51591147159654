import React from "react";
import { useNavigate } from "react-router-dom";
export default function Offer() {
  const navigate = useNavigate();
  const navigateto = (path) => {
    navigate(`/${path}`);
  };

  return (
    <div>
      <div className="container bg-white">
        <div className="main-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="left-content">
                  <div className="thumb">
                    <img
                      src={"https://mainprojectimg.s3.amazonaws.com/b1.1.jpg"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="right-content">
                  <div className="row">
                    <div
                      className="col-lg-6"
                      onClick={() => navigateto("MOBILES")}
                    >
                      <div className="right-first-image">
                        <div className="thumb">
                          <div className="inner-content">
                            <h4>Mobiles</h4>
                            <span>Best Mobiles</span>
                          </div>
                          <div className="hover-content">
                            <div className="inner">
                              <h4>Mobiles</h4>
                              <p>Explore More Moblies & Offer</p>
                              <div className="main-border-button">
                                <a>Discover More</a>
                              </div>
                            </div>
                          </div>
                          <img
                            src={
                              "https://mainprojectimg.s3.amazonaws.com/b2.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6"
                      onClick={() => navigateto("LAPTOPS")}
                    >
                      <div className="right-first-image">
                        <div className="thumb">
                          <div className="inner-content">
                            <h4>Laptops</h4>
                            <span>Best laptops</span>
                          </div>
                          <div className="hover-content">
                            <div className="inner">
                              <h4>Laptops</h4>
                              <p>Explore More Laptops & Offer</p>
                              <div className="main-border-button">
                                <a>Discover More</a>
                              </div>
                            </div>
                          </div>
                          <img
                            src={
                              "https://mainprojectimg.s3.amazonaws.com/b3.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6"
                      onClick={() => navigateto("TABLETS")}
                    >
                      <div className="right-first-image">
                        <div className="thumb">
                          <div className="inner-content">
                            <h4>Tablets</h4>
                            <span>Mega Sale Is Going On</span>
                          </div>
                          <div className="hover-content">
                            <div className="inner">
                              <h4>Tablets</h4>
                              <p>Explore The Mega Sale Offer</p>
                              <div className="main-border-button">
                                <a>Discover More</a>
                              </div>
                            </div>
                          </div>
                          <img
                            src={
                              "https://mainprojectimg.s3.amazonaws.com/b4.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="right-first-image"
                        onClick={() => navigateto("/News")}
                      >
                        <div className="thumb">
                          <div className="inner-content">
                            <h4>News Update</h4>
                            <span>Latest News</span>
                          </div>
                          <div className="hover-content">
                            <div
                              className="inner"
                              onClick={() => navigateto("News")}
                            >
                              <h4>News Update</h4>
                              <p>See our update on our earlier story. </p>
                              <div className="main-border-button">
                                <a href="/">Discover More</a>
                              </div>
                            </div>
                          </div>
                          <img
                            src={
                              "https://mainprojectimg.s3.amazonaws.com/b1.2.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
