import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import AdminTitle from "./AdminTitle";
import { Link, useParams } from "react-router-dom";
import {
  adminProductBrief,
  adminProductDelete,
} from "../../Service/admin-service";

// Import Font Awesome icons
import { FaEdit, FaPlus, FaTrash, FaTrashRestoreAlt } from "react-icons/fa";

export default function ProductList() {
  const [data, setData] = useState([]);
  const [totalCount, settotalCount] = useState([]);
  const { category, brand } = useParams();

  const getData = async () => {
    try {
      const response = await adminProductBrief(category, brand);
      setData(response.products);
      settotalCount(response.totalCount);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteProduct = async (_id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        const response = await adminProductDelete(_id);
        alert(response.message);
        getData(); // Refresh the product list after deletion
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          <AdminTitle
            title1={"Admin Product Brief"}
            title2={`Total Brand ${totalCount}`}
          />

          <div className="row">
            <div className="col-lg-2 col-md-6 mb-4 m-3">
              {" "}
              <Link
                to={`/admin/${category}/${brand}/addproduct`}
                className="btn btn-primary mx-1">
                <FaPlus />
              </Link>
              Add Product
            </div>
            {data?.map((product, index) => (
              <div key={index} className="col-lg-2 col-md-6 mb-4 m-3">
                <div className="card h-100">
                  <img
                    src={product.img}
                    className="card-img-top"
                    alt={product.model_name}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{product.model_name}</h5>
                    <div className="d-flex justify-content-between">
                      <Link to={`/admin/update-product/${product._id}`}>
                        <button className="btn btn-primary">
                          <FaEdit /> {/* Edit icon */}
                        </button>
                      </Link>
                      <Link to={`/admin/modified-product/${product._id}`}>
                        <button className="btn btn-warning">
                          <FaTrashRestoreAlt /> {/* Modify icon */}
                        </button>
                      </Link>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDeleteProduct(product._id)}>
                        <FaTrash /> {/* Delete icon */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
