import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import SmallCard from "../SmallCard";
import AdminTitle from "../AdminTitle";
import {
  adminHomeCategory,
  addCategoryHomeCategory,
  fetchCategoryHomeCategory,
  UpdateCategoryHomeCategory,
  deleteCategoryHomeCategory,
} from "../../../Service/admin-service";
import { Link } from "react-router-dom";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";

export default function AdminHC() {
  const [data, setData] = useState([]);
  const [TotalCategory, SetTotalCategory] = useState(0);
  const [showInput, setShowInput] = useState(false);
  const [category, setCategory] = useState("");
  const formdata = {
    category: category,
  };
  const getData = async () => {
    try {
      let response = await adminHomeCategory();
      if (response.success === true) {
        setData(response.results);
        SetTotalCategory(response.totalCategory);
      }
    } catch (error) {
      console.log("error in admin home category count", error);
    }
  };

  const toggleInput = () => {
    setShowInput(!showInput);
  };

  const handleDelete = async (oldcategory) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (confirmed) {
      try {
        const response = await deleteCategoryHomeCategory(oldcategory);

        if (response.success === true) {
          alert(response.message);
          getData();
        }
      } catch (error) {
        console.error("Error deleting documents:", error);
      }
    }
  };

  const handleUpdate = async (category) => {
    try {
      // Fetch existing category data
      const response = await fetchCategoryHomeCategory(category);

      if (response.success === true) {
        const oldCatgeory = response.result.category;
        // Prompt user for new category
        const newCategory = prompt("Enter a new category:", oldCatgeory);

        if (newCategory !== null) {
          const updateResponse = await UpdateCategoryHomeCategory(
            oldCatgeory,
            newCategory
          );

          if (updateResponse.success === true) {
            // Show success message
            alert(updateResponse.message);
            // Refresh data
            getData();
          } else {
            // Show error message if update failed
            alert(updateResponse.message);
          }
        } else {
          // User cancelled input
          console.log("User cancelled input for new category");
        }
      } else {
        // Show error message if fetch failed
        console.error("Error fetching existing data:", response.message);
        alert("Error fetching existing data");
      }
    } catch (error) {
      console.error("Error updating category:", error);
      alert("Error updating category");
    }
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    try {
      const response = await addCategoryHomeCategory(formdata);
      if (response.success === true) {
        alert(response.message);
        setCategory("");
        getData();
      }
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          <AdminTitle
            title1={"Admin Category"}
            title2={`Total Category ${TotalCategory}`}
          />

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4 m-3">
              <button
                className="btn btn-primary"
                type="button"
                onClick={toggleInput}>
                Add Category
              </button>
              {showInput && (
                <div className="input-group mt-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Category"
                    name="Category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => handleAddCategory(e)}>
                    Add
                  </button>
                </div>
              )}
            </div>
            {data?.map((item, index) => (
              <div key={index} className="col-lg-2 col-md-6 mb-4 m-3">
                <Link to={`/admin/home-category/brand?q=${item.category}`}>
                  <SmallCard title={item.category} content={item.totalBrand} />
                </Link>

                <button
                  className="btn btn-primary mx-1"
                  onClick={() => handleUpdate(item.category)}>
                  <FaEdit />
                </button>
                {/* <button
                  className="btn btn-primary mx-1"
                  onClick={() => handleDelete(item.category)}>
                  <FaTrash />
                </button> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
