import { createContext, useContext } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const storeTokenInLocalStorage = (name, value) => {
    return localStorage.setItem(name, value);
  };

  const getTokenFromLocalStorage = (name) => {
    return localStorage.getItem(name);
  };
  const removeTokenFromLocalStorage = (name) => {
    return localStorage.removeItem(name);
  };

  const authFunctions = {
    storeToken: storeTokenInLocalStorage,
    getToken: getTokenFromLocalStorage,
    removeToken: removeTokenFromLocalStorage,
  };

  return (
    <AuthContext.Provider value={authFunctions}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const checkAuthContextvalue = useContext(AuthContext);
  if (!checkAuthContextvalue) {
    throw new Error("useAuth used outside of the provider");
  }
  return checkAuthContextvalue;
};
