import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { adminProduct } from "../../Service/adminService";
import Sidebar from "./Sidebar";
import SmallCard from "./SmallCard";

export default function Product() {
  const [data, setData] = useState([]);
  const [totalCount, settotalCount] = useState(0);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const category = queryParams.get("category");
  const brand = queryParams.get("brand");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await adminProduct(category, brand); // Pass category to the service function
        settotalCount(response.totalCount);
        setData(response.products);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [category, brand]); // Re-fetch data when category changes

  return (
    <div className="container-fluid">
      <h1 className="admin_heading">Product Detail</h1>
      <div className="row">
        <div className="col-md-2">
          <Sidebar />
        </div>
      </div>
      <h1 className="admin_heading">{category}</h1>
      <div className="row">
        <div className="row">
          {data?.map((item, index) => (
            <div key={index} className="col-md-2">
              {/* <Link
                to={`/admin/brand/product?brand=${encodeURIComponent(
                  item._id
                )}`}> */}
              <SmallCard
                // title={`Ids: ${item._id}`}
                // content={`category: ${item.category}`}
                content2={`brand: ${item.brand}`}
                content3={`modelname: ${item.model_name}`}
              />
              {/* </Link> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
