import React, { useState, useEffect } from "react";
import {
  adminAddNews,
  adminReadAllNews,
  adminDeleteNews,
} from "../../Service/admin-service";
import Sidebar from "../Dashboard/Sidebar";
import CustomCard from "../Dashboard/CustomCard";
import { useNavigate } from "react-router-dom";

export default function AdminNews() {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    img: "",
  });
  const [newsData, setNewsData] = useState([]);
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await adminAddNews(formData);
      alert(response.message);
      fetchData();
      setFormData({
        title: "",
        description: "",
        img: "",
        date: "",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (_id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this news?"
    );

    // If user confirms deletion
    if (confirmDelete) {
      try {
        const response = await adminDeleteNews(_id);
        alert(response.message);
        fetchData();
      } catch (error) {
        console.error("Error in deleting news:", error);
      }
    }
  };

  const fetchData = async () => {
    try {
      const response = await adminReadAllNews();
      if (response.success === true) setNewsData(response.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUpdate = async (_id) => {
    navigate(`/admin/news/update-news/${_id}`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          <div className="container">
            <h2>Add News</h2>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Title:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Image URL:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="img"
                      value={formData.img}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Description:</label>
                  <textarea
                    className="form-control"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}></textarea>
                </div>
              </div>
              <button type="submit" className="adminaddnew">
                Add New
              </button>
            </form>
          </div>
          <div className="container mt-5">
            <div className="row">
              {newsData.length === 0 ? (
                <div className="col-12 text-center">
                  <p>No news available.</p>
                </div>
              ) : (
                newsData.map((card, index) => (
                  <CustomCard
                    key={index}
                    title={card.title}
                    description={card.description}
                    img={card.img}
                    date={card.createdAt}
                    onDelete={() => handleDelete(card._id)}
                    onUpdate={() => handleUpdate(card._id)}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
