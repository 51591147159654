import api from "../Service/apiHelper";

export const subscribeNewsletter = async (email) => {
  try {
    const response = await api.post(`api/v1/newsletter/subscribeNewsletter`, {
      email,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const unsubscribeNewsletter = async (email) => {
  try {
    const response = await api.post(`api/v1/newsletter/unsubscribeNewsletter`, {
      email,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.message || "An error occurred";
  }
};
