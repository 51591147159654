import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { adminUserList, adminUserDelete } from "../../Service/admin-service";
import AdminTitle from "./AdminTitle";
export default function UserList() {
  const [Users, setUsers] = useState([]);

  const getAllUser = async () => {
    try {
      const response = await adminUserList();

      setUsers(response.users);
    } catch (error) {
      console.log("error in get user detail", error);
    }
  };

  useEffect(() => {
    getAllUser();
  }, []);

  // Function to handle removing a user
  const handleDelete = async (userId) => {
    const confirmDelte = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmDelte) {
      try {
        const response = await adminUserDelete(userId);
        alert(response.message);
        getAllUser();
      } catch (error) {
        console.log("error in delete", error);
      }
    }
  };

  return (
    <div className="main container">
      <div className="row">
        <div className="col-12">
          <div className="col-4 ">
            <Sidebar />
          </div>

          <AdminTitle />
          <div className="col-8 ">
            <div className="container mt-5">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Users?.map((user) => (
                      <tr key={user.id}>
                        <td>{user._id}</td>
                        <td>{user.fullName}</td>
                        <td>{user.email}</td>
                        <td>
                          <button
                            className="btn btn-danger btn-sm m-1"
                            onClick={() => handleDelete(user._id)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
